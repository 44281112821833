import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import Aos from "aos";
import "aos/dist/aos.css";

import allPrintsMeyoko from "../../data__AllMeyoko.js";
import { DividersSm, LgImgCircleComp } from "../../components/index.js";

import { HeadlinePatterOpt2 } from "../../headlineComponents/index.js";
import {
  bottleWhiteCircleComp,
  NephentesDetail,
} from "../../headlineComponents/indexImg.js";
import "./styles-allPatterns.scss";

const Index = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const { ref, inView } = useInView();
  const animationOnviewHookH1 = useAnimation();
  const animationOnviewHookH2 = useAnimation();

  useEffect(() => {
    if (inView) {
      animationOnviewHookH1.start({
        transition: {
          ease: [0.6, 0.01, -0.05, 0.95],
          duration: 1.8,
          delay: 0.1,
        },
      });
      animationOnviewHookH2.start({
        scale: 1,
        y: 0,
        transition: {
          ease: [0.6, 0.01, -0.05, 0.95],
          duration: 1.8,
          delay: 0.9,
        },
      });
    }

    if (!inView) {
      animationOnviewHookH1.start({
        borderTopLeftRadius: "0px",
        borderTopRightRadius: "0px",
        borderBottomLeftRadius: "0px",
        borderBottomRightRadius: "0px",
      });

      animationOnviewHookH2.start({
        scale: 1.3,
        y: -750,
        borderTopLeftRadius: "180px",
        borderTopRightRadius: "180px",
        borderBottomLeftRadius: "10px",
        borderBottomRightRadius: "10px",
      });
    }
  }, [inView, animationOnviewHookH1, animationOnviewHookH2]);

  return (
    <section id="all-patterns">
      <HeadlinePatterOpt2
        titlePatt="&#x2724;"
        subTitlePatt1="The"
        subTitlePatt2="Playground"
      />
      <LgImgCircleComp img={bottleWhiteCircleComp} />
      <DividersSm />

      <HeadlinePatterOpt2
        titlePatt="&#x2724;"
        subTitlePatt1="Our"
        subTitlePatt2="Selection"
      />

      <div className="wrapper-pattern1">
        <div className="container-pattern1">
          <motion.div ref={ref} className="containerGrid-pattern1">
            {allPrintsMeyoko
              .filter((product) => product.categories === "Allpatterns")
              .map((itemPattern) => (
                <motion.div
                  className="container-card-pattern1"
                  key={`${itemPattern._id}`}
                >
                  <div className="img-container-pattern1">
                    <div className="container-imgBox-pattern1">
                      <NavLink
                        to={`/folio-pattern/${itemPattern._id}/${itemPattern.extraUrlName}`}
                      >
                        {" "}
                        <motion.div
                          className="img-boxPattern-pattern1"
                          style={{
                            backgroundImage: `url(${NephentesDetail})`,
                            backgroundRepeat: "no-repeat",
                            backgroundAttachment: "fixed",
                            backgroundSize: "cover",
                            backgroundPosition: "center 30%",
                          }}
                        >
                          <motion.img
                            src={itemPattern.coverImg}
                            alt={itemPattern.altDrawingScreen}
                          />
                        </motion.div>{" "}
                      </NavLink>
                    </div>
                  </div>

                  <div className="description-pattern1">
                    <div className="wrapper-text-pattern-1">
                      <div className="container-text-pattern-1">
                        <h1
                          data-aos="fade-down"
                          data-aos-easing="linear"
                          data-aos-duration="700"
                        >
                          {itemPattern.title}
                        </h1>
                        {itemPattern.spanTitle && (
                          <h2
                            data-aos="fade-down"
                            data-aos-easing="linear"
                            data-aos-duration="850"
                          >
                            {itemPattern.spanTitle}
                            <span>{itemPattern.subTitle}</span>
                          </h2>
                        )}
                      </div>
                    </div>
                  </div>
                </motion.div>
              ))}
          </motion.div>
        </div>
      </div>

      <HeadlinePatterOpt2 titlePatt="&#x2724;" />
    </section>
  );
};

export default Index;
