import { createContext, useState, useEffect } from "react";

//

//
const ColorChangeBgOnScrollContext = createContext();
export function ColorChangeBgProvider({ children }) {
  //
  // ** COLOR ------ change -------
  //
  //

  const [color, setColor] = useState(true);
  const [activeSection, setActiveSection] = useState("section-1"); // Correct initial section

  const changeColorsOnScroll = () => {
    // CHECK the color on  the Styles-Home.scss (at the bottom)
    //
    const sections = [
      { id: "section-1", color: "section-bg-white-dove-color" },
      { id: "section-2", color: "section-bg-white-dove-color" },
      { id: "section-3", color: "section-bg-black-color" },
    ];

    for (const section of sections) {
      const sectionElement = document.getElementById(section.id);

      if (sectionElement) {
        //
        const rect = sectionElement.getBoundingClientRect();
        //
        const isSectionInViewport =
          rect.top <= window.innerHeight / 2 && rect.bottom >= 0;

        if (isSectionInViewport) {
          setColor(section.color);
          setActiveSection(section.id);
          return; // Exit the loop once a section is found in the viewport
        }
      }
    }
    // ------
    // If no section is in the viewport, set a default color
    setColor("section-bg-white-dove-color");
  };

  // --------------------
  useEffect(() => {
    window.addEventListener("scroll", changeColorsOnScroll);
    return () => {
      window.removeEventListener("scroll", changeColorsOnScroll);
    };
  }, []);

  //

  return (
    //

    //
    <ColorChangeBgOnScrollContext.Provider
      value={{
        color,
        setColor,
        activeSection,
        setActiveSection,

        // query,
        // setQuery,
        // movies,
        // setMovies,
        //
      }}
    >
      {children}
    </ColorChangeBgOnScrollContext.Provider>
  );
}
export default ColorChangeBgOnScrollContext;
