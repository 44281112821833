import { useEffect } from "react";

import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import FadeBlurScaleOnEnter from "../../components/AnimationProps/FadeBlurScaleOnEnter";

import HeadlinePatterOpt2 from "../headlinePatterPrint";

import "./style-archComp.scss";

import Aos from "aos";
import "aos/dist/aos.css";

import { DividersSm } from "../../components";

import { StarForArch } from "../../headlineComponents";

const About = ({
  titlePatt,
  subTitlePatt1,
  subTitlePatt2,

  imgArch,
  imgArchBg,

  h1TitleArchDesc,

  spanArchDesc1,
  pTitleArchDesc,
  spanArchDesc2,
  pTitleArchDesc1,
  spanArchDesc3,
  pTitleArchDesc2,
  spanArchDesc4,
  pTitleArchDesc3,
}) => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const { ref, inView } = useInView();
  const animationOnviewHookH1 = useAnimation();

  useEffect(() => {
    if (inView) {
      animationOnviewHookH1.start({
        y: 0,
        transition: {
          type: "spring",
          duration: 1,
          damping: 14,
          delay: 0.8,
        },
      });
    }

    if (!inView) {
      animationOnviewHookH1.start({
        y: 650,
      });
    }
  }, [inView, animationOnviewHookH1]);

  return (
    <section ref={ref} id="wrapper__archComponent">
      <div className="container___archComponent">
        <div className="wrapper-Grid_Top_About">
          <div
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="700"
          >
            <HeadlinePatterOpt2
              titlePatt={titlePatt}
              subTitlePatt1={subTitlePatt1}
              subTitlePatt2={subTitlePatt2}
            />
            <DividersSm />
          </div>

          <div className="container_Grid_Top_About">
            <div className="star-absolute1">
              <p>&#x2724;</p>
            </div>
            <div className="stari-absolute2">
              <p>&#x2724;</p>
            </div>

            <div className="star-left_tryptic_arch-img">
              <StarForArch colorSvgStar1="#cccccc" colorSvgStar2="black" />
            </div>

            <div className="wrapper__img_topAbout">
              <div className="container__img_topAbout">
                <div
                  className="imgBox_topAbout"
                  style={{
                    backgroundRepeat: "no-repeat",
                    backgroundImage: `url(${imgArchBg})`,
                    backgroundAttachment: "fixed",
                    backgroundSize: "cover",
                    backgroundPosition: " center right",
                  }}
                >
                  <FadeBlurScaleOnEnter>
                    <motion.img
                      src={imgArch}
                      drag
                      whileDrag={{ scale: 1.4 }}
                      dragConstraints={{
                        top: -220,
                        right: 220,
                        bottom: 220,
                        left: -220,
                      }}
                      alt="photograph of the artist"
                    />
                  </FadeBlurScaleOnEnter>
                </div>
              </div>
            </div>

            <div className="star-right_tryptic_arch-img">
              <StarForArch colorSvgStar1="#cccccc" colorSvgStar2="black" />
            </div>
          </div>

          <div className="wrapper-description__about-screen">
            <div className="container-text-pattern-1">
              <div className="headlineH1_3box_text">
                <span className="headlineBlock_a">&#x2724;</span>
                {h1TitleArchDesc && (
                  <div className="h1-box-3box">
                    <h1>{h1TitleArchDesc}</h1>
                  </div>
                )}
                <span className="headlineBlock_b">&#x2724;</span>
              </div>
            </div>
          </div>
        </div>

        <div className="container-P_arch">
          <h1>
            <span className="underlineP-pattern">
              {spanArchDesc1 ? spanArchDesc1 : ""}
            </span>
            {pTitleArchDesc ? pTitleArchDesc : ""}
          </h1>
          <p
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1000"
          >
            <span className="underlineP-pattern">
              {spanArchDesc2 ? spanArchDesc2 : ""}
            </span>
            {pTitleArchDesc1 ? pTitleArchDesc1 : ""}
          </p>
          <br></br>
          <p
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1800"
          >
            <span className="underlineP-pattern">
              {spanArchDesc3 ? spanArchDesc3 : ""}
            </span>
            {pTitleArchDesc2 ? pTitleArchDesc2 : ""}
            <span className="underlineP-pattern">
              {spanArchDesc4 ? spanArchDesc4 : ""}
            </span>
            {pTitleArchDesc3 ? pTitleArchDesc3 : ""}
          </p>
        </div>

        <HeadlinePatterOpt2 titlePatt="&#x2724;" />
        <DividersSm />
      </div>
    </section>
  );
};

export default About;
