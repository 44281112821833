/*

-    category: "homescreen-gallery-skull", IS going to map only the category containing the desktop, its because in the homescreen i have the memento component and i only want to show 2 divs or blocks , not more than that, that is why i only added this "homescreen-gallery-skull" for these 2 (check the GalleryItems )


*/

//
//
//
const collectionsMeyoko = [
  //
  //-----------------
  // Collection: ARCHIVE
  //-----------------
  {
    _id: "0",
    extraUrlName: "collection-001-the-debut-collection",
    name: "0.01",
    artCreationDate: "2008 / 2011",
    description:
      "Meyoko's debut collection emerges from a diverse tapestry of influences, where darkness intertwines with beauty in unexpected ways. Inspired by the visionary creations of Alexander McQueen 'JOAN (AW98)' and the haunting photography of Nick Knight 'Devon Aoki for Alexander Mcqueen, 1997', Meyoko found her creative calling.",
    description_2:
      "Drawing from the depths of history and human experience, Meyoko's art delves into the darker realms of Victorian mental asylum patients and the gothic tales of the Grimm brothers. However, her inspiration is not merely aesthetic; it is a reflection of the horrors endured by humanity, from the atrocities of the American slave trade to the unimaginable suffering inflicted by torture devices.",
    description_3:
      "Though Meyoko's work confronts the darkness of our past, it does so with a purpose—to evoke remembrance and ensure that such atrocities are never forgotten. Through her art, she strives to shed light on the darkest corners of history, sparking conversations and igniting change, even within the realm of fashion.",

    brand: "allArchive",
    category: "all-archive-debut-2008-2011",
    materials: "ink, digital collage",
    collection: "the-debut",
    //
    //
    // VIDEO
    videoCollectionDrawings: "/assets/videos/wip-hunter-by-meyoko.mp4",
    videoShortDescriptionAlt: "work in progress of Hunter",
    //
    nameDrawingAltCollection: "drawing vespertine by meyoko",
    //
    image: "/assets/img-archiv/neon.jpg",
    // IMG Large 1
    imageLargeCollectionDebut1: "/assets/img-archiv/vespertine.jpg",

    // IMG Large 2
    imageLargeCollectionDebut2: "/assets/img-archiv/unravel.jpg",
    //
    //
    //unravel.jpg
    // 4 x drawings
    itemDrawingCollection: [
      {
        id: 0,
        nameDrawing: "arum 0.1",
        imgDrawing: "/assets/img-archiv/arum_01_Meyoko-MelissaMurillo.jpg",
        height: 25,
        width: 20,
        mediums: "ink ballpoint pen",
        itemDrawingCollectionAlt: "Arum 0.1 drawing by meyoko",
      },
      {
        id: 1,
        nameDrawing: "arum 0.2",
        imgDrawing: "/assets/img-archiv/arum_02_Meyoko-MelissaMurillo.jpg",
        height: 25,
        width: 20,
        mediums: "ink ballpoint pen",
        itemDrawingCollectionAlt: "Arum 0.2 drawing by meyoko",
      },
      {
        id: 2,
        nameDrawing: "Neon",
        imgDrawing: "/assets/img-archiv/neon.jpg",
        height: 25,
        width: 20,
        mediums: "ink ballpoint pen",
        itemDrawingCollectionAlt: "neon drawing by meyoko",
      },
      {
        id: 3,
        nameDrawing: "pleurothallis",
        imgDrawing:
          "/assets/img-archiv/pleurothallis_Meyoko-MelissaMurillo.jpg.jpeg",
        height: 25,
        width: 20,
        mediums: "ink ballpoint pen",
        itemDrawingCollectionAlt: "pleurothallis drawing by meyoko",
      },
    ],
    //
    //
    //
    //-------------
    // --- tags
    tags: [
      {
        id: 0,
        label: "drawing",
      },
    ],
  },
  {
    _id: "1",
    extraUrlName: "collection-002-conspiration-a-la-reine",
    name: "0.02",
    artCreationDate: "2009 / 2011",
    description:
      "The Conspiration a la reine collection , 1Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur voluptatibus corporis, magni accusamus impedit esse ducimus porro similique animi! Maiores doloribus voluptate atque accusantium laborum fuga hic vero, incidunt itaque dicta voluptatem odit aut!",

    brand: "allArchive",
    category: "all-archive-debut-2009-2011",
    materials: "ink, digital collage",
    collection: "the-conspiration-a-la-reine",
    //
    // VIDEO
    videoCollectionDrawings: "/assets/videos/wip-hunter-by-meyoko.mp4",
    videoShortDescriptionAlt: "work in progress of Hunter",
    //
    nameDrawingAltCollection: "drawing conspiration a la reine by meyoko",

    image: "/assets/img-archiv/arum_02_Meyoko-MelissaMurillo.jpg",
    //
    //
    // IMG Large 1
    imageLargeCollectionDebut1:
      "/assets/img-archiv/Andromeda_Meyoko-MelissaMurillo.jpg",
    // IMG Large 2
    imageLargeCollectionDebut2:
      "/assets/img-archiv/volta_Meyoko-MelissaMurillo.jpg",
    //
    //
    // 4 x drawings
    itemDrawingCollection: [
      {
        id: 0,
        nameDrawing: "Euphorbe",
        imgDrawing: "/assets/img-archiv/nebulosa.jpg",
        height: 59,
        width: 42,
        mediums: "ink, acrylic, ballpoint pen, collage",
      },
      {
        id: 1,
        nameDrawing: "conspiration a la Reine",
        imgDrawing:
          "/assets/img-archiv/conspiration-a-la-reine_Meyoko-MelissaMurillo.jpg",
        height: 59,
        width: 42,
        mediums: "ink, acrylic, ballpoint pen, collage",
      },
      {
        id: 2,
        nameDrawing: "conspiration a la Reine 3",
        imgDrawing: "/assets/img-archiv/conspiration-a-la-reine-3.jpg",

        height: 59,
        width: 42,
        mediums: "ink, acrylic, ballpoint pen, collage",
      },
      {
        id: 3,
        nameDrawing: "conspiration a la Reine 2",
        imgDrawing: "/assets/img-archiv/consp-a-la-reine2.jpg",
        height: 59,
        width: 42,
        mediums: "ink, acrylic, ballpoint pen, collage",
      },
    ],
    //
    //
    // 2 x drawings
    itemDrawingCollection1: [
      {
        id: 0,
        nameDrawing: "medulla",
        imgDrawing: "/assets/img-archiv/medulla_Meyoko-MelissaMurillo.jpg.jpg",
        height: 25,
        width: 20,
        mediums: "ink ballpoint pen",
        itemDrawingCollectionAlt: "Arum 0.1 drawing by meyoko",
      },
      {
        id: 1,
        nameDrawing: "nouvelle vague",
        imgDrawing:
          "/assets/img-archiv/nouvelle-vague_Meyoko-MelissaMurillo.jpg.jpg",
        height: 25,
        width: 20,
        mediums: "ink ballpoint pen",
        itemDrawingCollectionAlt: "Arum 0.2 drawing by meyoko",
      },
    ],
    //
    //

    // --- tags
    tags: [
      {
        id: 0,

        label: "drawing",
      },
    ],
  },

  {
    _id: "2",
    extraUrlName: "collection-003-the-hunters",
    name: "0.03",
    artCreationDate: "2011 / 2013",
    description:
      "The Hunters Collection, 1Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur voluptatibus corporis, magni accusamus impedit esse ducimus porro similique animi! Maiores doloribus voluptate atque accusantium laborum fuga hic vero, incidunt itaque dicta voluptatem odit aut!",

    brand: "allArchive",
    category: "all-archive-debut-2008-2011",
    materials: "ink, digital collage",
    collection: "the-furies",
    //
    //
    // VIDEO
    videoCollectionDrawings: "/assets/videos/wip-hunter-by-meyoko.mp4",
    videoShortDescriptionAlt: "work in progress of Hunter",
    //
    nameDrawingAltCollection: "drawing hunter by meyoko",
    //
    image: "/assets/The-furies/alecto-detail3-web.jpg",
    // IMG Large 1
    imageLargeCollectionDebut1: "/assets/img-archiv/hunter_no1-by-meyoko.jpg",
    // IMG Large 2
    // imageLargeCollectionDebut2: "/assets/The-furies/alecto-detail3-web.jpg",
    // //
    //
    //
    //unravel.jpg
    // 4 x drawings
    itemDrawingCollection: [
      {
        id: 0,
        nameDrawing: "hunter no.4",
        imgDrawing: "/assets/img-archiv/hunter_no4-by-meyoko.jpg",
        height: 25,
        width: 20,
        mediums: "ink ballpoint pen",
        itemDrawingCollectionAlt: "hunter no.4 drawing by meyoko",
      },
      {
        id: 1,
        nameDrawing: "hunter no.3",
        imgDrawing: "/assets/img-archiv/hunter_no3-by-meyoko.jpg",
        height: 59,
        width: 42,
        mediums: "ink, acrylic, ballpoint pen, collage",
        itemDrawingCollectionAlt: "hunter no.3 drawing by meyoko",
      },

      {
        id: 2,
        nameDrawing: "hunter no.4",
        imgDrawing: "/assets/img-archiv/hunter_no2-by-meyoko.jpg",
        height: 25,
        width: 20,
        mediums: "ink ballpoint pen",

        itemDrawingCollectionAlt: "hunter no.2 drawing by meyoko",
      },
      {
        id: 3,
        nameDrawing: "hunter no.1",
        imgDrawing: "/assets/img-archiv/hunter_no1-by-meyoko.jpg",
        height: 25,
        width: 20,
        mediums: "ink ballpoint pen",
        itemDrawingCollectionAlt: "hunter no.1 drawing by meyoko",
      },
    ],
    //
    //
    // 2 x drawings

    //
    //-------------
    // --- tags
    tags: [
      {
        id: 0,
        label: "drawing",
      },
      {
        id: 1,
        label: "drawing",
      },
    ],
  },
];

export default collectionsMeyoko;
