import React from "react";
import { useLocation } from "react-router-dom";

const Section2 = ({ activeSection }) => {
  //
  const location = useLocation();

  //
  return (
    <div id="section_02">
      <div id="container_section_02">
        <h1
          className={
            location.pathname === "/" && activeSection === "section-3"
              ? "footer-section2_font-white-h1"
              : //otherwise, if it's not  ==="/" or not section-2, then show green
                "footer-section2_font-black-h1"
          }
        >
          {/* SECT 02 */}
          MAIL
        </h1>
        <h2
          className={
            location.pathname === "/" && activeSection === "section-3"
              ? "footer-section2_font-white-h2"
              : //otherwise, if it's not  ==="/" or not section-2, then show green
                "footer-section2_font-black-h2"
          }
        >
          PARIS | BERLIN | QUITO
        </h2>
        <h3
          className={
            location.pathname === "/" && activeSection === "section-3"
              ? "footer-section2_font-white-h3"
              : //otherwise, if it's not  ==="/" or not section-2, then show green
                "footer-section2_font-black-h3"
          }
        >
          CONTACT@MEYOKOCREATIVE.COM
        </h3>
        {/*

        LINKS 🍥

         */}
        <div className="container-social-links">
          <ul className="ul-social-grid">
            {socialLinkFooter.map((e) => (
              <li key={e.id}>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={e.SocialLink}
                  className={
                    location.pathname === "/" && activeSection === "section-3"
                      ? "footer-section2_font-white-a_link"
                      : //otherwise, if it's not  ==="/" or not section-2, then show green
                        "footer-section2_font-black-a_link"
                  }
                >
                  {e.SocialLabel}
                </a>
              </li>
            ))}
          </ul>
        </div>
        {/*






        star  ❤️‍🔥

         */}
        <div id="container-svg-footer">
          <svg
            //
            className="svg-box-footer"
            // ---------
            width="200"
            height="200"
            viewBox="0 0 200 200"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_118_198)">
              <path
                d="M100 0L102.665 74.6397L120.791 2.18524L107.88 75.7481L140.674 8.64545L112.75 77.9164L158.779 19.0983L117.063 81.0498L174.314 33.0869L120.63 85.0115L186.603 50L123.295 89.6282L195.106 69.0983L124.943 94.6982L199.452 89.5471L125.5 100L199.452 110.453L124.943 105.302L195.106 130.902L123.295 110.372L186.603 150L120.63 114.989L174.314 166.913L117.063 118.95L158.779 180.902L112.75 122.084L140.674 191.355L107.88 124.252L120.791 197.815L102.665 125.36L100 200L97.3345 125.36L79.2088 197.815L92.1201 124.252L59.3263 191.355L87.25 122.084L41.2215 180.902L82.9372 118.95L25.6855 166.913L79.3701 114.989L13.3975 150L76.7046 110.372L4.89435 130.902L75.0572 105.302L0.547813 110.453L74.5 100L0.547813 89.5471L75.0572 94.6982L4.89435 69.0983L76.7046 89.6282L13.3975 50L79.3701 85.0115L25.6855 33.0869L82.9372 81.0498L41.2215 19.0983L87.25 77.9164L59.3263 8.64545L92.1201 75.7481L79.2088 2.18524L97.3345 74.6397L100 0Z"
                fill="url(#svgImgStyles)"
              />
            </g>
            <defs>
              <linearGradient
                id="svgImgStyles"
                x1="100"
                y1="0"
                x2="100"
                y2="200"
                gradientUnits="userSpaceOnUse"
              >
                {/*  //🧧 i added the property form the array to the svg */}
                <stop
                  // before changes
                  // stopColor="#030303"
                  // ------
                  // after changes 1
                  stopColor={
                    location.pathname === "/" && activeSection === "section-3"
                      ? "#fffae9ea"
                      : "#23232368"
                  }
                />
                <stop
                  offset="1"
                  // stopColor="#030303"

                  stopColor={
                    location.pathname === "/" && activeSection === "section-3"
                      ? "#fffae9ea"
                      : "#23232368"
                  }
                />
              </linearGradient>
              <clipPath id="clip0_118_198">
                <rect
                  width="200"
                  height="200"
                  fill={
                    location.pathname === "/" && activeSection === "section-3"
                      ? "#fffae9ea"
                      : "#23232368"
                  }
                />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default Section2;

const socialLinkFooter = [
  {
    id: "0",
    SocialLabel: "Facebook",
    SocialLink: "https://www.facebook.com/MelissaMurilloofficial/",
  },
  {
    id: "1",
    SocialLabel: "Instagram",
    SocialLink: "https://www.instagram.com/meyokocreative/",
  },
  // {
  //   id: "2",
  //   SocialLabel: "tiktok",
  //   SocialLink: "https://www.instagram.com/mm_meyoko/",
  // },
];
