import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { motion, LayoutGroup } from "framer-motion";
import NavMenuOpen from "./NavMenuOpen";

const Navbar = () => {
  const [show, setShow] = useState(true);
  const [scrollPos, setScrollPos] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    //
    const handleScroll = () => {
      setScrollPos(document.body.getBoundingClientRect().top);
      setShow(document.body.getBoundingClientRect().top > scrollPos);
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollPos]);

  return (
    <>
      <LayoutGroup type="crossfade">
        <motion.header
          initial={{ opacity: 0, background: "transparent" }}
          animate={{
            opacity: 1,
            background: isMenuOpen ? "#050505" : "transparent",
          }}
          transition={{ duration: 0.5 }}
          className={show ? "active" : "hidden"}
        >
          <motion.div
            className="header-child"
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,

              borderBottom: `1.9px solid ${
                isMenuOpen ? "#fdfbf3" : "transparent"
              }`,

              width: isMenuOpen ? "98%" : "100%",
            }}
            transition={{ duration: 0.5 }}
          >
            <NavLink
              to="/"
              style={{ color: "black", textDecoration: "none" }}
              onClick={() => setIsMenuOpen()}
            >
              <motion.div
                initial={{ opacity: 0, color: "#212121" }}
                animate={{
                  opacity: 1,
                  color: isMenuOpen ? "#fdfbf3" : "#212121",
                }}
                transition={{ duration: 0.5 }}
                className="logo-meyoko-creat"
              >
                <motion.h5
                  initial={{ opacity: 0, color: "#212121" }}
                  animate={{
                    opacity: 1,
                    color: isMenuOpen ? "#fdfbf3" : "#2d2a26",
                    fontSize: isMenuOpen ? "30px" : "32px",
                  }}
                  className="logo1"
                >
                  Meyoko
                </motion.h5>
                <motion.h5
                  initial={{ opacity: 0, color: "#212121" }}
                  animate={{
                    opacity: 1,
                    filter: isMenuOpen ? `invert(0%)` : `invert(20%)`,
                    color: isMenuOpen ? "#fdfbf3" : "#2d2a26",
                    fontSize: isMenuOpen ? "30px" : "32px",
                    lineHeight: isMenuOpen ? "22px" : "18px",
                    fontWeight: isMenuOpen ? "200" : "300",
                  }}
                  className="logo2"
                >
                  Creative
                </motion.h5>
              </motion.div>
            </NavLink>

            <motion.div
              initial={{
                opacity: 0,
                color: "#212121",
                mixBlendMode: "difference",
              }}
              animate={{
                opacity: 1,
                color: isMenuOpen ? "#fdfbf3" : "#212121",
                mixBlendMode: "difference",
              }}
              transition={{ duration: 0.5 }}
              className="hamburger"
              onClick={toggleMenu}
            >
              <svg
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                width="48" // Adjusted width for a larger hamburger
                height="48" // Adjusted height for a larger hamburger
              >
                <path
                  d="M3 12h18M3 6h18M3 18h18"
                  stroke="currentColor"
                  strokeWidth="1" // Adjusted to make the lines thinner
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </motion.div>
          </motion.div>
        </motion.header>

        {isMenuOpen && (
          <motion.div
            initial={{ opacity: 0, background: "#fdfbf3" }}
            animate={{
              opacity: 1,
              background: isMenuOpen ? "#050505" : "transparent",
            }}
            transition={{ duration: 0.5 }}
            exit={{ opacity: 0 }}
            className="menu-overlay"
          >
            <NavMenuOpen
              isMenuOpen={isMenuOpen}
              setIsMenuOpen={setIsMenuOpen}
            />
          </motion.div>
        )}
      </LayoutGroup>
    </>
  );
};

export default Navbar;
