import React from "react";
import { NavLink } from "react-router-dom";

import "./styles-btnEye.scss";

const Index = ({ productUrlLink, urlToShop }) => {
  return (
    <div className="wrapper-BTN-eye" style={{ background: "yello" }}>
      <ul className="ul_BTN-eye">
        <li className="li_BTN-eye">
          {productUrlLink ? (
            <a
              className="btn-box"
              rel="noopener noreferrer"
              target="_blank"
              href={productUrlLink} //
            >
              <button>Add to Bag</button>
            </a>
          ) : (
            <a
              className="btn-box"
              rel="noopener noreferrer"
              target="_blank"
              href={urlToShop} //
            >
              <button>Check the Store</button>
            </a>
          )}

          {/*

          👁️ POSITION absolute 👁️

           */}
          <NavLink to="/prints-collections">
            <span className="small-SVG-icon-BTN-eye">
              <div className="container-small-SVG-icon-BTN-eye">
                <svg
                  className="svg-box-small-BTN-eye"
                  //
                  width="200"
                  height="200"
                  viewBox="0 0 200 200"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M99.9994 153.877C141.147 153.877 176.851 127.54 194.627 111.673C201.789 105.28 201.789 94.597 194.627 88.204C176.851 72.3371 141.147 46 99.9994 46C58.8516 46 23.1479 72.3371 5.37163 88.2041C-1.79055 94.597 -1.79054 105.28 5.37164 111.673C23.1479 127.54 58.8516 153.877 99.9994 153.877ZM99.9994 137.57C120.783 137.57 137.631 120.722 137.631 99.9383C137.631 79.1551 120.783 62.3069 99.9994 62.3069C79.2161 62.3069 62.368 79.1551 62.368 99.9383C62.368 120.722 79.2161 137.57 99.9994 137.57Z"
                    fill="url(#paint0_linear_105_535)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_105_535"
                      x1="157.499"
                      y1="63.2603"
                      x2="106.827"
                      y2="158.86"
                      gradientUnits="userSpaceOnUse"
                    >
                      {/*  //🧧 i added the property form the array to the svg */}
                      <stop
                        // in case you have different background colors, just change the pathname to the page you want and add curly brackets to add the prop containing a color or just the color you want
                        //
                        // stopColor={itemPress.svgSymbolColor}
                        //   stopColor={
                        //     location.pathname === "/" &&
                        //     activeSection === "section-3"
                        //       ? "#111111"
                        //       : "#23232368"
                        //   }
                        stopColor="#000000"
                      />
                      <stop
                        offset="1"
                        // stopColor={
                        //   location.pathname === "/" &&
                        //   activeSection === "section-3"
                        //     ? "#111111"
                        //     : "#23232368"
                        // }
                        stopColor="#000000"
                      />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
            </span>
          </NavLink>
          {/*


           */}
        </li>
      </ul>
    </div>
  );
};

export default Index;
