//
//  ** ---- IMG
import { AboutImg2 } from "../../headlineComponents/indexImg";
//
// ** ------ OTHER COMPONENTS
import { ArchComponent } from "../../headlineComponents";
//  ------- STYLES
import "./style-about.scss";
//  -------

//
//
const About = () => {
  // ------ framer -----------

  //
  //
  return (
    <section id="about-page">
      <ArchComponent
        titlePatt="The Motto"
        subTitlePatt1="'Beauty"
        subTitlePatt2="and Dreams'"
        imgArch={AboutImg2}
        // left & right text (cote a cote of the middle img)
        h1MiddleTextLeft="Ethereal grace, gentle kindness, serenity"
        h2MiddleTextRight=" Every unfolding moment fuels my creativity"
        //
        //
        // description of the component
        h1TitleArchDesc="Who we are?"
        h2TitleArchDesc="Melissa Murillo"
        spanTitleArchDesc="aka Meyoko"
        // span within the P tag
        spanArchDesc1="Meyoko,"
        pTitleArchDesc="also known as Melissa Murillo, an artist who invites viewers into captivating narratives through meticulously crafted scenes."
        spanArchDesc2=" Her work explores a world transformed, where hybrid characters and fantastical fauna"
        pTitleArchDesc1=" inhabit lush forests brimming with imaginary flora. Here, you can delve deeper into her captivating world and bring a piece of it into your own."
        spanArchDesc3="We offer a curated selection of high-quality prints,meticulously reproduced to capture the full depth of Meyoko's artistic vision."
        pTitleArchDesc2="Additionally, we also offer commissions"
        spanArchDesc4=" Interested in commissioning a unique artwork or in need of personalized illustration services?"
        pTitleArchDesc3="Reach out to us, and together, we'll transform your concepts into tangible creations. Share your vision, along with any samples or references, and we'll be thrilled to assist you every step of the way."
      />
    </section>
  );
};

export default About;
