//
export const DeviceSize = {
  mobile: 680,
  tablet: 992,
  laptop: 1366,
  desktop: 2024,
};

/*
  MOBILE
@media only screen and (max-width: 680px) and (min-width: 170px) {
  border: 2px solid #000;
}
// --------
  TABLET1
@media only screen and (max-width: 992px) and (min-width: 681px) {
  border: 2px solid #000;
}
// --------
 TABLET2 / LAPTOP
@media only screen and (max-width: 1366px) and (min-width: 993px) {
  border: 2px solid #000;
}
// --------
  DESKTOP
  1440 x 900
    http://responsivetesttool.com/
@media only screen and (max-width: 1666px) and (min-width: 1367px) {
  border: 2px solid #000;
}


*/
