import { Fragment } from "react";
import { NavLink, useParams } from "react-router-dom";
import allDataMeyoko from "../../data__AllMeyoko.js";
import { motion } from "framer-motion";
import ImgTag from "./ImgTag.jsx";
import "./styles-tagsPage.scss";

const TagPage = () => {
  const { label, id } = useParams();
  const tagItem = allDataMeyoko.find((tItem) => tItem._id === id);
  const filteredProducts = allDataMeyoko.filter((media) =>
    media.tags.some((tag) => tag.label === label)
  );

  return (
    <>
      <section id="tag-page">
        <div className="tag-title-wrapper">
          <div className="tag-title-container">
            {tagItem.tags.map((tag) => (
              <Fragment key={tag.id}>
                <div className="tag-title-container-btns">
                  <NavLink
                    to={`/tags/${tag.id}/${tag.label}`}
                    className="tag-button"
                  >
                    <motion.button
                      whileHover={{
                        scale: 1.1,
                        transition: {
                          type: "spring",
                          duration: 1,
                          damping: 23,
                          delay: 0.08,
                        },
                      }}
                      type="button"
                    >
                      {tag.label && <>{tag.label}</>}
                    </motion.button>
                  </NavLink>
                </div>
              </Fragment>
            ))}
          </div>
        </div>

        <motion.div className="container-images-random">
          {filteredProducts.map((media) => (
            <ImgTag key={`${media._id}`} media={media} />
          ))}
        </motion.div>
      </section>
    </>
  );
};

export default TagPage;
