import React from "react";
import { NavLink } from "react-router-dom";

//
//
//

const Project = ({ id, title, extraUrlName, artCreationDate, setModal }) => {
  //
  //
  return (
    <div
      className="container-project-modal"
      onMouseEnter={() => {
        setModal({ active: true, id: id });
      }}
      // when leaving
      onMouseLeave={() => {
        setModal({ active: false, id: id });
      }}
    >
      <NavLink to={`/folio-drawing-archive/${id}/${extraUrlName}`}>
        <h2>{title}</h2>
        {/* <h1>{item.name}</h1>*/}
      </NavLink>
      <p>{artCreationDate}</p>
      {/* <p>Design & Development</p> */}
    </div>
  );
};

export default Project;
