import { motion } from "framer-motion";

const DragZoomImg = ({ children }) => {
  return (
    <motion.div
      drag
      whileDrag={{ scale: 2.2 }}
      dragConstraints={{
        top: -35,
        right: 35,
        bottom: 35,
        left: -35,
      }}
    >
      {children}
    </motion.div>
  );
};

export default DragZoomImg;
