import random from "lodash.random";
import { useEffect, useState } from "react";

export const useRandomNumberHook = ({ min, max }) => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(random(min, max));
  }, [min, max]);

  return width;
};
