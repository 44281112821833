import React from "react";
import { NavLink } from "react-router-dom";

import { motion } from "framer-motion";
import ClipPathDropdownImg from "../../components/AnimationProps/ClipPath-DropdownImg-on-enter";

const ImgTag = ({ media }) => {
  return (
    <>
      <motion.div className="media-wrapper">
        <motion.ul className="wrapper-img-random-card">
          <li className="container-img-random">
            <NavLink to={`/folio-drawing/${media._id}/${media.extraUrlName}`}>
              <ClipPathDropdownImg>
                <motion.img
                  whileHover={{
                    scale: 1.1,
                    transition: { duration: 0.6, staggerChildren: 0.1 },
                  }}
                  style={{
                    "--aspect-ratio": media.aspectRatio,
                  }}
                  src={media.coverImg}
                  alt="image tags"
                />
              </ClipPathDropdownImg>
            </NavLink>
          </li>
        </motion.ul>
      </motion.div>
    </>
  );
};

export default ImgTag;
/*







<div className="media-wrapper">
        <motion.ul
          style={{ "--img-width": width, "--image-margin": marginLefti }}
          className="wrapper-img-random"
          key={`${media.source}_${media._id}`}
        >
          <motion.li className="container-img-random">
            <NavLink
              to={`/folio-drawing/${media.source}/${media._id}/${media.extraUrlName}`}
            >
              <motion.img
                //   animate={animationOnviewHookH1}
                src={media.image}
                alt=""
              />
            </NavLink>
          </motion.li>
        </motion.ul>
      </div> */
