import React from "react";
import "./styles-starforArch.scss";

const Index = ({ colorSvgStar1, colorSvgStar2 }) => {
  return (
    <div className="container-svg-start-arch">
      <svg
        className="svg-box-arch"
        width="100"
        height="100"
        viewBox="0 0 200 200"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_118_208)">
          <path
            d="M100 200C97.1048 105.262 94.738 102.91 0 100C94.738 97.1048 97.0903 94.738 100 0C102.895 94.738 105.262 97.0903 200 100C105.262 102.91 102.91 105.233 100 200Z"
            fill="url(#paint0_linear_118_208)"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_118_208"
            x1="14"
            y1="26"
            x2="179"
            y2="179.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorSvgStar1} />
            <stop offset="1" stopColor={colorSvgStar2} />
          </linearGradient>
          <clipPath id="clip0_118_208">
            <rect width="200" height="200" fill="black" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default Index;
