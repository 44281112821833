import { useEffect, Fragment } from "react";
import { useParams, NavLink } from "react-router-dom";
//

// ** - This component is being required within the App.jsx , its the only place where you will be importing it, as
// - This component contains the individual info about the product/drawing (price,description and other sort of info)
//
import { BouncingBtn, FadeBlurScaleOnEnter, PressBtn } from "../components";

//

//  ------- STYLES
import "./styles_pressScreen.scss";
//
//  ------- DATA
import allPressData from "../data_press.js";
//
// ------ FRAMER
//
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
//  the comp below, contains the vaues to manipulate the animation

// ------ AOS
import Aos from "aos";
import "aos/dist/aos.css";

//
//
// const container = {
//   hidden: { opacity: 0, scale: 0 },
//   visible: {
//     opacity: 1,
//     scale: 1,
//     transition: {
//       delayChildren: 0.3,
//       staggerChildren: 0.2,
//     },
//   },
// };

// const item = {
//   hidden: { y: 20, opacity: 0 },
//   visible: {
//     y: 0,
//     opacity: 1,
//   },
// };

//
//
const PressScreen = () => {
  // ------ framer -----------
  const { ref, inView } = useInView();
  const animationOnviewHook = useAnimation();
  // h1
  const animationOnviewHookH1 = useAnimation();
  // h3
  const animationOnviewHookH3 = useAnimation();
  //
  useEffect(() => {
    if (inView) {
      animationOnviewHook.start({
        y: 0,
        transition: {
          type: "spring",
          duration: 1,
          damping: 16,
          delay: 0.08,
        },
      });

      animationOnviewHookH1.start({
        y: 0,
        transition: {
          type: "spring",
          duration: 1,
          damping: 13,
          delay: 0.8,
        },
      });
      animationOnviewHookH3.start({
        y: 0,
        transition: {
          type: "spring",
          duration: 1,
          damping: 16,
          delay: 0.18,
        },
      });
    }

    if (!inView) {
      animationOnviewHook.start({
        y: 350,
      });

      animationOnviewHookH1.start({
        y: 850,
      });

      animationOnviewHookH3.start({
        y: 350,
      });
    }
    //
  }, [
    inView,
    animationOnviewHookH3,
    animationOnviewHookH1,
    animationOnviewHook,
  ]);

  //
  //
  //
  //
  //
  // If you add the useEffect at the bottom, you will have an error
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  // //
  //
  //
  //----------

  const { id } = useParams();
  // Assuming 'portfolioWelcome' is your array of products
  const itemPress = allPressData.find((p) => p.__id === id);
  //
  if (!itemPress) {
    return <div>Drawing not 😀found!</div>;
  }

  //
  //
  //
  return (
    <section
      ref={ref}
      id="wrapper__Press-Screen"
      style={{
        "--bg-color": itemPress.backgroundColor,
      }}
    >
      <div className="headline-press">
        <div className="container-svg">
          {/*


🔴 This svg shouldnt be replaced with the RotatingStar, as this one contains a different color for each page of the press array, you pass itdown like so:


  <stop stopColor={itemPress.svgSymbolColor} />
                <stop offset="1" stopColor={itemPress.svgSymbolColor} />

         */}
          <motion.svg
            //
            className="svg-box-press"
            //
            animate={animationOnviewHookH1}
            //
            width="200"
            height="200"
            viewBox="0 0 200 200"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_118_198)">
              <path
                d="M100 0L102.665 74.6397L120.791 2.18524L107.88 75.7481L140.674 8.64545L112.75 77.9164L158.779 19.0983L117.063 81.0498L174.314 33.0869L120.63 85.0115L186.603 50L123.295 89.6282L195.106 69.0983L124.943 94.6982L199.452 89.5471L125.5 100L199.452 110.453L124.943 105.302L195.106 130.902L123.295 110.372L186.603 150L120.63 114.989L174.314 166.913L117.063 118.95L158.779 180.902L112.75 122.084L140.674 191.355L107.88 124.252L120.791 197.815L102.665 125.36L100 200L97.3345 125.36L79.2088 197.815L92.1201 124.252L59.3263 191.355L87.25 122.084L41.2215 180.902L82.9372 118.95L25.6855 166.913L79.3701 114.989L13.3975 150L76.7046 110.372L4.89435 130.902L75.0572 105.302L0.547813 110.453L74.5 100L0.547813 89.5471L75.0572 94.6982L4.89435 69.0983L76.7046 89.6282L13.3975 50L79.3701 85.0115L25.6855 33.0869L82.9372 81.0498L41.2215 19.0983L87.25 77.9164L59.3263 8.64545L92.1201 75.7481L79.2088 2.18524L97.3345 74.6397L100 0Z"
                fill="url(#svgImgStyles)"
              />
            </g>
            <defs>
              <linearGradient
                id="svgImgStyles"
                x1="100"
                y1="0"
                x2="100"
                y2="200"
                gradientUnits="userSpaceOnUse"
              >
                {/*  //🧧 i added the property form the array to the svg */}
                <stop stopColor={itemPress.svgSymbolColor} />
                <stop offset="1" stopColor={itemPress.svgSymbolColor} />
              </linearGradient>
              <clipPath id="clip0_118_198">
                <rect
                  width="200"
                  height="200"
                  fill={itemPress.svgSymbolColor}
                />
              </clipPath>
            </defs>
          </motion.svg>
        </div>

        {/* TITLE 🧡 */}
        <h1
          //--- AOS ----
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="500"
          // -----
          style={{
            "--font-color-headlineTop": itemPress.headlineTitleLgTop,
          }}
        >
          {itemPress.title}
        </h1>
      </div>

      <div
        className="container___Press-Screen"
        style={{
          "--font-color-headlineTop": itemPress.headlineTitleLgTop,
        }}
      >
        <div className="wrapper-description__Press-screen">
          <div className="container-description__Press-screen">
            <div className="desc_press-screen_left">
              <h1
                style={{
                  "--font-color-h1": itemPress.lgTitle,
                }}
              >
                {itemPress.title}
              </h1>
              {itemPress.productCountry && (
                <h3
                  style={{
                    "--font-color-h3": itemPress.smTitle,
                  }}
                >
                  {itemPress.productCountry}
                  {itemPress.publishingDate && (
                    <span
                      style={{
                        "--font-color-h3": itemPress.smTitle,
                      }}
                      // style={{
                      //   "--font-color-h3": itemPress.smTitle,
                      // }}
                    >
                      | {itemPress.publishingDate}
                    </span>
                  )}
                </h3>
              )}
            </div>

            <div className="desc_press-screen_middle">
              <h1
                style={{
                  "--font-color-h1": itemPress.lgTitle,
                }}
              >
                About
              </h1>
              {itemPress.descPress1 && (
                <motion.p
                  // ---- framer
                  animate={animationOnviewHookH3}
                  // -----
                  style={{
                    "--font-color-P": itemPress.paragraphDescription,
                  }}
                >
                  {itemPress.descPress1}
                </motion.p>
              )}
              {itemPress.descPress2 && (
                <p
                  style={{
                    "--font-color-P": itemPress.paragraphDescription,
                  }}
                >
                  {itemPress.descPress2}
                </p>
              )}
              {itemPress.descPress3 && (
                <p
                  style={{
                    "--font-color-P": itemPress.paragraphDescription,
                  }}
                >
                  {itemPress.descPress3}
                </p>
              )}
              {/*



              ---- BTN




              - 💮 */}

              {itemPress.linkPress1 && (
                <BouncingBtn>
                  <motion.div
                    // ---- framer
                    // -----
                    className="btn-press-middle"
                  >
                    <PressBtn
                      productUrlScreenShop={itemPress.linkPress1}
                      //
                      itemPress={itemPress}
                    />
                  </motion.div>
                </BouncingBtn>
              )}
            </div>
            <div className="desc_press-screen_right">
              <NavLink to="/press">
                <h1
                  style={{
                    "--font-color-h1": itemPress.lgTitle,
                  }}
                >
                  Back
                </h1>
              </NavLink>
            </div>
          </div>
        </div>
        {/*
        #####




  <ul className="press-grid">
            {allPressData.filter(
                    (eApparel) =>
                      eApparel.relatedPost ===
                      "editorial"
                  ).map((eEditorial) => (
              <>
                <li key={`${eEditorial._id}`} className="container-imgPress">
                  <NavLink
                    to={`/press/${eEditorial._id}/${eEditorial.extraUrlName}`}
                  >
                    <FadeBlurScaleOnEnter>
                      <motion.img
                        // --- framer
                        whileHover={{ scale: 1.1, color: "#3a3a3a" }}
                        //
                        className="imgPress-box"
                        src={eEditorial.coverImg}
                        alt=""
                      />{" "}
                    </FadeBlurScaleOnEnter>
                  </NavLink>
                </li>
              </>
            ))}{" "}
          </ul>




          💮 GRID */}
        {itemPress.relatedPost === "apparel" ? (
          <>
            <ul className="wrapper-img-double__Press-screen">
              {/* 1 */}
              {itemPress.imgPress2 && (
                <li className="imgBox_Press-Screen">
                  <FadeBlurScaleOnEnter>
                    <motion.img
                      // -- framer animation
                      // zoom img while drag
                      drag
                      whileDrag={{ scale: 1.4 }}
                      dragConstraints={{
                        top: -20,
                        right: 20,
                        bottom: 20,
                        left: -20,
                      }}
                      // HOVER
                      whileHover={{ scale: 1.1, color: "#3a3a3a" }}
                      // ------ framer------
                      src={itemPress.imgPress2}
                      alt=""
                    />
                  </FadeBlurScaleOnEnter>
                </li>
              )}

              {/* 2 */}

              {itemPress.imgPress3 && (
                <li className="imgBox_Press-Screen">
                  <FadeBlurScaleOnEnter>
                    <motion.img
                      // -- framer animation
                      // zoom img while drag
                      drag
                      whileDrag={{ scale: 1.4 }}
                      dragConstraints={{
                        top: -20,
                        right: 20,
                        bottom: 20,
                        left: -20,
                      }}
                      // HOVER
                      whileHover={{ scale: 1.1, color: "#3a3a3a" }}
                      // ------ framer------

                      src={itemPress.imgPress3}
                      alt=""
                    />
                  </FadeBlurScaleOnEnter>
                </li>
              )}
            </ul>

            {/*


        IMAGES


         */}
            {itemPress.imgPress1 && (
              <div className="container-single__Press-screen">
                <div className="imgBox_Press-Screen">
                  <FadeBlurScaleOnEnter>
                    <motion.img
                      // -- framer animation
                      // zoom img while drag
                      drag
                      whileDrag={{ scale: 1.4 }}
                      dragConstraints={{
                        top: -20,
                        right: 20,
                        bottom: 20,
                        left: -20,
                      }}
                      // HOVER
                      whileHover={{ scale: 1.1, color: "#3a3a3a" }}
                      // ------ framer------

                      src={itemPress.imgPress1}
                      alt=""
                    />
                  </FadeBlurScaleOnEnter>
                </div>
              </div>
            )}
          </>
        ) : itemPress.relatedPost === "editorial" ? (
          <>
            {/*
        🔴
          I added th IMAGES IN SUCH WAY ANN not mapping, because i wanted to have more freedom in case there is an object that doesnt contain an specific image, like for example, some objects only contain 1 img and others 5 or more, so in the mapping i have to grab them all


           */}
            <div className="wrapper-press-editorial-grid">
              <ul className="press-editorial-grid">
                {/* 1 */}
                {itemPress.imgPress1 && (
                  <li className="container-imgPress-editorial">
                    <div className="imgPress-box-editorial">
                      <FadeBlurScaleOnEnter>
                        <motion.img
                          // -- framer animation
                          // zoom img while drag
                          drag
                          whileDrag={{ scale: 1.4 }}
                          dragConstraints={{
                            top: -20,
                            right: 20,
                            bottom: 20,
                            left: -20,
                          }}
                          // HOVER
                          whileHover={{ scale: 1.1, color: "#3a3a3a" }}
                          // ------ framer------
                          src={itemPress.imgPress1}
                          alt=""
                        />
                      </FadeBlurScaleOnEnter>
                    </div>
                  </li>
                )}
                {/* 2 */}
                {itemPress.imgPress2 && (
                  <li className="container-imgPress-editorial">
                    <div className="imgPress-box-editorial">
                      <FadeBlurScaleOnEnter>
                        <motion.img
                          // -- framer animation
                          // zoom img while drag
                          drag
                          whileDrag={{ scale: 1.4 }}
                          dragConstraints={{
                            top: -20,
                            right: 20,
                            bottom: 20,
                            left: -20,
                          }}
                          // HOVER
                          whileHover={{ scale: 1.1, color: "#3a3a3a" }}
                          // ------ framer------
                          src={itemPress.imgPress2}
                          alt=""
                        />
                      </FadeBlurScaleOnEnter>
                    </div>
                  </li>
                )}

                {/* 3 */}

                {itemPress.imgPress3 && (
                  <li className="container-imgPress-editorial">
                    <div className="imgPress-box-editorial">
                      <FadeBlurScaleOnEnter>
                        <motion.img
                          // -- framer animation
                          // zoom img while drag
                          drag
                          whileDrag={{ scale: 1.4 }}
                          dragConstraints={{
                            top: -20,
                            right: 20,
                            bottom: 20,
                            left: -20,
                          }}
                          // HOVER
                          whileHover={{ scale: 1.1, color: "#3a3a3a" }}
                          // ------ framer------
                          src={itemPress.imgPress3}
                          alt=""
                        />
                      </FadeBlurScaleOnEnter>
                    </div>
                  </li>
                )}

                {/* 4 */}

                {itemPress.imgPress4 && (
                  <li className="container-imgPress-editorial">
                    <div className="imgPress-box-editorial">
                      <FadeBlurScaleOnEnter>
                        <motion.img
                          // -- framer animation
                          // zoom img while drag
                          drag
                          whileDrag={{ scale: 1.4 }}
                          dragConstraints={{
                            top: -20,
                            right: 20,
                            bottom: 20,
                            left: -20,
                          }}
                          // HOVER
                          whileHover={{ scale: 1.1, color: "#3a3a3a" }}
                          // ------ framer------
                          src={itemPress.imgPress4}
                          alt=""
                        />
                      </FadeBlurScaleOnEnter>
                    </div>
                  </li>
                )}

                {/* 5 */}

                {itemPress.imgPress5 && (
                  <li className="container-imgPress-editorial">
                    <div className="imgPress-box-editorial">
                      <FadeBlurScaleOnEnter>
                        <motion.img
                          // -- framer animation
                          // zoom img while drag
                          drag
                          whileDrag={{ scale: 1.4 }}
                          dragConstraints={{
                            top: -20,
                            right: 20,
                            bottom: 20,
                            left: -20,
                          }}
                          // HOVER
                          whileHover={{ scale: 1.1, color: "#3a3a3a" }}
                          // ------ framer------
                          src={itemPress.imgPress5}
                          alt=""
                        />
                      </FadeBlurScaleOnEnter>
                    </div>
                  </li>
                )}

                {/* 6 */}

                {itemPress.imgPress6 && (
                  <li className="container-imgPress-editorial">
                    <div className="imgPress-box-editorial">
                      <FadeBlurScaleOnEnter>
                        <motion.img
                          // -- framer animation
                          // zoom img while drag
                          drag
                          whileDrag={{ scale: 1.4 }}
                          dragConstraints={{
                            top: -20,
                            right: 20,
                            bottom: 20,
                            left: -20,
                          }}
                          // HOVER
                          whileHover={{ scale: 1.1, color: "#3a3a3a" }}
                          // ------ framer------
                          src={itemPress.imgPress6}
                          alt=""
                        />
                      </FadeBlurScaleOnEnter>
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </>
        ) : (
          "nothing here"
        )}{" "}
      </div>
    </section>
  );
};

export default PressScreen;

/*

{allPressData
                  .filter((eApparel) => eApparel.relatedPost === "editorial")
                  .map((editItem) => (
                    <>
                      <li
                        key={`${editItem._id}`}
                        className="container-imgPress-editorial"
                      >
                        <FadeBlurScaleOnEnter>
                          <motion.img
                            // --- framer
                            whileHover={{ scale: 1.1, color: "#3a3a3a" }}
                            //
                            className="imgPress-box"
                            src={editItem.coverImg}
                            alt=""
                          />
                        </FadeBlurScaleOnEnter>
                      </li>
                    </>
                  ))}

*/
