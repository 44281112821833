import { useEffect } from "react";
import { useLocation, NavLink } from "react-router-dom";

import "./AboutSection.scss";

import { motion, useAnimation } from "framer-motion";

import { useInView } from "react-intersection-observer";

import Aos from "aos";
import "aos/dist/aos.css";
//
//

const Index = ({
  titleh1line1,
  titleh1line2,
  titleh1line3,

  paragraph1,
  buttonText,

  activeSection,
  imgAboutSectionComponentProp,
}) => {
  const location = useLocation();

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const { ref, inView } = useInView();
  const animationOnviewHookH1 = useAnimation();
  const animationOnviewHookH2 = useAnimation();

  useEffect(() => {
    if (inView) {
      animationOnviewHookH1.start({
        y: 0,
        transition: {
          ease: [0.6, 0.01, -0.05, 0.95],
          duration: 1.8,
          delay: 0.1,
        },
      });
      animationOnviewHookH2.start({
        scale: 1,
        borderTopLeftRadius: "0px",
        borderTopRightRadius: "0px",
        borderBottomLeftRadius: "0px",
        borderBottomRightRadius: "0px",

        transition: {
          ease: [0.6, 0.01, -0.05, 0.95],
          duration: 1.8,

          delay: 0.9,
        },
      });
    }

    if (!inView) {
      animationOnviewHookH1.start({
        y: -650,
      });

      animationOnviewHookH2.start({
        scale: 3,
        borderTopLeftRadius: "180px",
        borderTopRightRadius: "180px",
        borderBottomLeftRadius: "10px",
        borderBottomRightRadius: "10px",
      });
    }
    //
  }, [inView, animationOnviewHookH1, animationOnviewHookH2]);
  //
  return (
    <>
      {location.pathname === "/" && (
        <>
          <div
            ref={ref}
            className={
              location.pathname === "/" && activeSection === "section-3"
                ? "is--hero-aboutWrapper-section-white"
                : "is--hero-aboutWrapper-section-black"
            }
          >
            <div
              className={
                location.pathname === "/" && activeSection === "section-3"
                  ? "is--hero-aboutContainer-section-white"
                  : "is--hero-aboutContainer-section-black"
              }
            >
              <div className="container-about-text">
                <h1
                  className={
                    location.pathname === "/" && activeSection === "section-3"
                      ? "is--hero-about-section-h1-white"
                      : "is--hero-about-section-h1-black"
                  }
                >
                  <>
                    <span className="hero-content-line">
                      <span className="hero-content-line-inner">
                        {titleh1line1}
                      </span>
                    </span>
                    <br />
                    <span className="hero-content-line">
                      <span className="hero-content-line-inner">
                        {titleh1line2}
                      </span>
                    </span>
                    <br />
                    <span className="hero-content-line">
                      <span className="hero-content-line-inner">
                        {titleh1line3}
                      </span>
                    </span>
                  </>
                </h1>

                <p
                  className={
                    location.pathname === "/" && activeSection === "section-3"
                      ? "is--hero-about-section-p-white"
                      : "is--hero-about-section-p-black"
                  }
                >
                  {paragraph1}
                </p>

                {location.pathname === "/" && (
                  <div className="btn-wrapper">
                    <NavLink
                      to={
                        activeSection === "section-2"
                          ? "/about"
                          : activeSection === "section-3" &&
                            "/patterns-collections"
                      }
                    >
                      <motion.button
                        type="button"
                        className={
                          location.pathname === "/" &&
                          activeSection === "section-3"
                            ? "is--hero-about-section-btn-white"
                            : "is--hero-about-section-btn-black"
                        }
                        whileHover={{
                          scale: 1.1,
                          transition: {
                            type: "spring",
                            duration: 1,
                            damping: 23,
                            delay: 0.08,
                          },
                        }}
                      >
                        {buttonText}
                      </motion.button>
                    </NavLink>
                  </div>
                )}
              </div>
            </div>

            <div
              className={
                location.pathname === "/" && activeSection === "section-3"
                  ? "aboutSection-imgWrapper-white"
                  : "aboutSection-imgWrapper-black"
              }
            >
              <motion.div
                className="aboutSection-img-container"
                animate={animationOnviewHookH1}
              >
                <NavLink
                  to={
                    activeSection === "section-2"
                      ? "/about"
                      : activeSection === "section-3" && "/patterns-collections"
                  }
                >
                  <motion.img
                    animate={animationOnviewHookH2}
                    src={imgAboutSectionComponentProp}
                    alt="img about section"
                  />
                </NavLink>
              </motion.div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Index;
