import { LayoutGroup, AnimatePresence } from "framer-motion"; // import BannerImage from "./BannerImage";

import Navbar from "./Navbar";
import "./Navbar.scss";
//
// ------------------------
//

const Index = () => {
  //

  return (
    <>
      <LayoutGroup type="crossfade">
        <AnimatePresence>
          <Navbar />
        </AnimatePresence>
      </LayoutGroup>
    </>
  );
};

export default Index;
