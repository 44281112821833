import React from "react";
import "./styles-error-comp.scss";
import { AboutImg2 } from "../../headlineComponents/indexImg";
import { ScaleInTransition } from "../../components";
import { NavLink } from "react-router-dom";

const Index = () => {
  return (
    <div className="wrapper-error-component">
      <div className="container-error-component">
        <div className="container-error-component-text">
          <h1>Beware!</h1>
          <h2>
            This way leads you to one that{" "}
            <span className="wavy-error">doesn't exist</span>{" "}
          </h2>
          <p>
            You may have mistyped the address, or the page has been moved to
            another URL. if you think this is an error contact support
          </p>

          <div className="wrapper-btnRound">
            <NavLink to="/">
              <button className="container-btnRound">Go Back</button>
            </NavLink>
          </div>
        </div>

        {/* IMG 🟠 */}
        <div className="container-error-component-img">
          <div className="container-img-error">
            <div className="imgBox-error">
              <ScaleInTransition>
                <img src={AboutImg2} alt="" />
              </ScaleInTransition>
            </div>
          </div>
        </div>
        {/*  */}
      </div>
    </div>
  );
};

export default Index;
