import React from "react";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import allPrintsMeyoko from "../../data__AllMeyoko.js";

import "./styles-allPrints-page2.scss";
import { HeadlinePatterOpt2 } from "../../headlineComponents/index.js";
import {
  DividerMsMedium,
  DividersSm,
  FooterPrintsSvg,
} from "../../components/index.js";

const Index = () => {
  return (
    <section id="allPrints_page2">
      <div className="allPrints_page2">
        <HeadlinePatterOpt2
          titlePatt="&#x2724;"
          subTitlePatt1="Memento"
          subTitlePatt2="Mori"
        />
        <DividersSm />

        <div className="wrapper_gallery_grid_2">
          <div className="container_gallery-card_grid-2">
            <ul className="container-images-and-text_2">
              {allPrintsMeyoko
                .filter(
                  (product) =>
                    product.category === "home-screen-gallery-mementoMori"
                )
                .map((itemSkull) => (
                  <li
                    key={`${itemSkull._id}`}
                    className="img-container-grid-2_memento"
                  >
                    <NavLink
                      style={{ cursor: "none" }}
                      to={`/folio-drawing/${itemSkull._id}/${itemSkull.extraUrlName}`}
                    >
                      <div className="container_img-box-grid-2">
                        <motion.div className="img-box-grid-2">
                          <motion.img
                            framer
                            src={itemSkull.coverImgTransp}
                            alt={itemSkull.altDrawingScreen}
                            whileHover={{
                              scale: 1.1,

                              transition: {
                                duration: 0.6,
                                staggerChildren: 0.1,
                              },
                            }}
                          />
                        </motion.div>
                      </div>
                    </NavLink>

                    <div className="description-grid-2">
                      <h1>{itemSkull.title}</h1>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>

      <DividerMsMedium />
      <HeadlinePatterOpt2 titlePatt="&#x2724;" />
      <DividerMsMedium />
      <FooterPrintsSvg />
    </section>
  );
};

export default Index;
