import { useEffect, Fragment } from "react";
import { useParams, NavLink } from "react-router-dom";
import { Gallery2X, LgImgCollection } from "../components";
import "./drawingArchiveScreen.scss";
import collectionsMeyoko from "../data_CollectionsData";
import { motion } from "framer-motion";
import Aos from "aos";
import "aos/dist/aos.css";

const container = {
  hidden: { opacity: 0, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2,
    },
  },
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

const DrawingArchiveScreen = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const { id } = useParams();
  const drawingItemArch = collectionsMeyoko.find((p) => p._id === id);

  const hasImages = !!(
    drawingItemArch.imageLargeCollectionDebut1 ||
    drawingItemArch.imageLargeCollectionDebut2 ||
    (drawingItemArch.itemDrawingCollection &&
      drawingItemArch.itemDrawingCollection.length > 0)
  );

  const hasVideo = !!drawingItemArch.videoCollectionDrawings;

  const minHeight = hasImages || hasVideo ? "10vh" : "200vh";

  if (!drawingItemArch) {
    return <div>Drawing not found!</div>;
  }

  return (
    <>
      <section id="wrapper-drawingScreen__archive">
        <div
          className="full-img-drawingScreen__archive"
          style={{
            "--min-height": minHeight,
          }}
        >
          {drawingItemArch.imageLargeCollectionDebut1 ? (
            <LgImgCollection
              img={
                drawingItemArch.imageLargeCollectionDebut1
                  ? drawingItemArch.imageLargeCollectionDebut1
                  : ""
              }
            />
          ) : (
            ""
          )}

          <div className="gallery-container-lg_1fr-2fr-1fr">
            <div className="gallery-lg-card-container_1fr-2fr-1fr">
              <>
                {drawingItemArch.itemDrawingCollection
                  ? drawingItemArch.itemDrawingCollection.map((e) => (
                      <Fragment key={e.id}>
                        <ul className="card-gallery-lg__1fr-2fr-1fr">
                          <li className="img-container-gallery-lg_1fr-2fr-1fr">
                            <div className="img-box-gallery-lg_1fr-2fr-1fr">
                              {e.imgDrawing ? (
                                <motion.img
                                  drag
                                  whileDrag={{ scale: 2.2 }}
                                  dragConstraints={{
                                    top: -20,
                                    right: 20,
                                    bottom: 20,
                                    left: -20,
                                  }}
                                  src={e.imgDrawing}
                                  alt={e.imgDrawing}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </li>
                        </ul>
                      </Fragment>
                    ))
                  : ""}
              </>
            </div>
          </div>

          {drawingItemArch.videoCollectionDrawings ? (
            <div className="responsive-video-wrapper">
              <div className="responsive-video-container">
                <video
                  src={drawingItemArch.videoCollectionDrawings}
                  alt={drawingItemArch.videoShortDescriptionAlt}
                  autoPlay
                  loop
                  muted
                  className="responsive-video"
                ></video>
              </div>
            </div>
          ) : (
            ""
          )}

          {drawingItemArch.imageLargeCollectionDebut2 ? (
            <LgImgCollection
              img={
                drawingItemArch.imageLargeCollectionDebut2
                  ? drawingItemArch.imageLargeCollectionDebut2
                  : ""
              }
            />
          ) : (
            ""
          )}

          <Gallery2X />
        </div>

        <div className="wrapper_text-drawingScreen__archive">
          <div className="container_text-drawingScreen__archive">
            <div className="card_text-container-drawingScreen__archive">
              <div className="card-text-description-drawingScreen__archive">
                <div className="list-title-btn-desc-drawingScreen__archive">
                  <div className="list-title-wrapper-drawingScreen__archive">
                    <h1>{drawingItemArch.name}</h1>
                    {drawingItemArch.artCreationDate ? (
                      <h2>{drawingItemArch.artCreationDate}</h2>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="list-group-item-drawingScreen__archive">
                  {drawingItemArch.description ? (
                    <p className="paragraph_P_archiveDrawing">
                      {drawingItemArch.description}
                    </p>
                  ) : (
                    ""
                  )}
                  {drawingItemArch.description_2 ? (
                    <p>{drawingItemArch.description_2}</p>
                  ) : (
                    ""
                  )}
                  {drawingItemArch.description_3 ? (
                    <p>{drawingItemArch.description_3}</p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

            <div className="card-container-screenDraw__tags">
              <div className="card-tags-screen-draw">
                <div className="listGroup-title-tags">
                  <h3>Tags:</h3>
                </div>
                <motion.ul
                  variants={container}
                  initial="hidden"
                  animate="visible"
                  //
                  className="column-wrap-screen-draw"
                >
                  {drawingItemArch.tags.map((tag) => (
                    <Fragment key={tag.id}>
                      <motion.li
                        className="listGroupTags"
                        variants={item}
                        whileHover={{
                          scale: 1.1,
                        }}
                      >
                        <NavLink
                          to={`/tags/${tag.id}/${tag.label}`}
                          className="tag-button"
                        >
                          #{tag.label}
                        </NavLink>
                      </motion.li>
                    </Fragment>
                  ))}
                </motion.ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DrawingArchiveScreen;
