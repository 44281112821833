import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { LayoutGroup } from "framer-motion";
import "./scss/main.scss";
import { ColorChangeBgProvider } from "./Context";
// ** ---- COMPS  ----
import NavBar from "./components/Navigation";
import Home from "./pages/home/HomePage";
// ** ---- PAGES  ----
import AllPrints from "./pages/allPrints";
import AllPrintsPage1 from "./pages/allPrintsPage1";
import AllPrintsPage2 from "./pages/allPrintsPage2";
import AllPrintsPage3 from "./pages/allPrintsPage3";
import AllPatterns from "./pages/allPatterns";
// Err
import ErrorComponent from "./pages/error";

//
import About from "./pages/about";
import Press from "./pages/press";
import Contact from "./pages/contact";
import Memento from "./pages/mementoMori/Memento";
// ** ---- TAGS  ----
import TagPage from "./pages/tags-page/TagPage";
// ** screens
import DrawingScreen from "./screens/DrawingScreen.jsx";
import DrawingArchiveScreen from "./screens/DrawingArchiveScreen";
import PressScreen from "./screens/PressScreen";
import PatternScreen from "./screens/PatternScreen";
import ReactRouterScrollToTopPath from "./ScrollToTop_Path.js";

function App() {
  return (
    <LayoutGroup>
      <ColorChangeBgProvider>
        <Router>
          <ReactRouterScrollToTopPath />
          <div className="page">
            <NavBar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" exact element={<About />} />
              <Route path="/contact" exact element={<Contact />} />
              <Route path="/prints-collections" exact element={<AllPrints />} />
              <Route path="/prints-page-1" exact element={<AllPrintsPage1 />} />
              <Route path="/prints-page-2" exact element={<AllPrintsPage2 />} />
              <Route path="/prints-page-3" exact element={<AllPrintsPage3 />} />
              <Route
                path="/patterns-collections"
                exact
                element={<AllPatterns />}
              />
              <Route
                path="/folio-pattern/:id/:extraUrlName"
                exact
                element={<PatternScreen />}
              />
              <Route path="/tags/:id/:label" exact element={<TagPage />} />
              <Route
                path="/folio-drawing/:id/:extraUrlName"
                exact
                element={<DrawingScreen />}
              />
              <Route
                path="/folio-drawing-archive/:id/:extraUrlName"
                exact
                element={<DrawingArchiveScreen />}
              />
              <Route
                path="/press-screen/:id/:extraUrlName"
                exact
                element={<PressScreen />}
              />
              <Route path="/memento-mori" exact element={<Memento />} />
              <Route path="/press" exact element={<Press />} />
              {/* error */}
              <Route path="*" element={<ErrorComponent />} />
            </Routes>
          </div>
        </Router>
      </ColorChangeBgProvider>
    </LayoutGroup>
  );
}

export default App;
