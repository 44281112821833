import {
  // useLocation,
  NavLink,
} from "react-router-dom";
import { useEffect } from "react";
import "./styles-btn_DOT.scss";

import { useLocation } from "react-router-dom";

import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";

const Index = ({
  redirectPath,

  activeSection,
  titleHeadline_h1_textArrowBtn,
  titleHeadline_h2_textArrowBtn,
  //
}) => {
  const location = useLocation();

  const { ref, inView } = useInView();
  const animationOnviewHook = useAnimation();
  // h1
  const animationOnviewHookH1 = useAnimation();
  // h3
  const animationOnviewHookH3 = useAnimation();
  //
  useEffect(() => {
    if (inView) {
      animationOnviewHook.start({
        y: 0,
        transition: {
          type: "spring",
          duration: 1,
          damping: 16,
          delay: 0.08,
        },
      });

      animationOnviewHookH1.start({
        y: 0,
        transition: {
          type: "spring",
          duration: 1,
          damping: 13,
          delay: 0.4,
        },
      });
      animationOnviewHookH3.start({
        y: 0,
        transition: {
          type: "spring",
          duration: 1,
          damping: 16,
          delay: 0.18,
        },
      });
    }

    if (!inView) {
      animationOnviewHook.start({
        y: -50,
      });

      animationOnviewHookH1.start({
        y: -50,
      });

      animationOnviewHookH3.start({
        y: -50,
      });
    }
    //
  }, [
    inView,
    animationOnviewHook,
    animationOnviewHookH1,
    animationOnviewHookH3,
  ]);

  return (
    <motion.div ref={ref} className="wrapper-textArrowBtn">
      <div className="container-textArrowBtn">
        <motion.h1
          animate={animationOnviewHookH3}
          className={
            location.pathname === "/" && activeSection === "section-3"
              ? "textArrowBtn_font-white-h1"
              : "textArrowBtn_font-black-h1"
          }
        >
          {titleHeadline_h1_textArrowBtn}
        </motion.h1>

        <div
          id="circle-dot"
          className={
            location.pathname === "/" && activeSection === "section-3"
              ? "dot-white"
              : "dot-black"
          }
        ></div>

        <h2
          className={
            location.pathname === "/" && activeSection === "section-3"
              ? "textArrowBtn_font-white-h2"
              : "textArrowBtn_font-black-h2"
          }
        >
          <NavLink to={redirectPath}>{titleHeadline_h2_textArrowBtn}</NavLink>
        </h2>
      </div>
    </motion.div>
  );
};

export default Index;
