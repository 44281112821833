/*

-    category: "homescreen-gallery-skull", IS going to map only the category containing the desktop, its because in the homescreen i have the memento component and i only want to show 2 divs or blocks , not more than that, that is why i only added this "homescreen-gallery-skull" for these 2 (check the GalleryItems )


*/

//
//
//
const allDataMeyoko = [
  //
  //-----------------
  // 🧁 The furies 🧁
  //-----------------
  //
  {
    _id: "0",
    extraUrlName: "the-furies-Alecto",
    name: "Alecto",
    artCreationDate: "2012",
    description:
      "Meyoko's creation of this triptych of 'the Furies' was spurred not by personal anger, but by a deep fascination with the myth of Alecto, one of the Furies in Greek mythology. Alecto, symbolizing eternal anger and vengeance, became a focal point for the artist's exploration.",
    description_2:
      "While crafting the series, the artist was deeply affected by the plight of women in Ciudad Juarez, particularly the cases of the missing women. This harrowing reality influenced the artist's decision to delve into the myth of the Furies, as one of them is said to seek justice for victims of egregious offenses.",
    description_3:
      "Incorporating themes of justice and retribution, the artist's interpretation of the myth serves as a poignant commentary on the ongoing struggles faced by women in Ciudad Juarez and beyond. Through the lens of ancient mythology, the artist sheds light on contemporary issues of violence against women, seeking to evoke empathy and provoke reflection on the urgent need for change.",
    brand: "allDrawings",
    category: "home-screen-gallery-the-furies",
    height: 59,
    width: 42,
    materials: "ink, acrylic, ballpoint pen, collage",
    image: "/assets/The-furies/alecto-web_meyoko_frame.jpg",
    image2: "/assets/The-furies/AlectoByMeyoko__frme.jpg",
    image3: "/assets/The-furies/alecto-detail2-web(1).jpg ",
    image4: "/assets/The-furies/alecto-detail3-web.jpg",
    imgGalleryLgSection: "/assets/The-furies/alecto-web_meyoko_lowre.jpg",
    collection: "the-furies",

    tags: [
      {
        id: 0,
        label: "drawing",
      },
      {
        id: 1,
        label: "watercolor",
      },
      {
        id: 2,
        label: "theFuries",
      },
      {
        id: 3,
        label: "collage",
      },
      {
        id: 4,
        label: "mythology",
      },
      {
        id: 5,
        label: "alecto",
      },
    ],
  },
  {
    _id: "1",
    extraUrlName: "the-furies-tisiphone",
    name: "Tisiphone",
    artCreationDate: "2012",
    description:
      "In Athens, Meyoko found herself at a friend's house. While browsing through his library,  She stumbled upon a book about fashion. In it, She rediscovered Petrus Christus 'A Young Lady' which sparked the idea for her drawing.",
    description_2:
      "Tisiphone, featured in the drawing, is one of the Erinyes or Furies in Greek mythology. Known for punishing crimes such as murder, Tisiphone, along with her sisters Alecto and Megaera, embodies the relentless pursuit of justice. Legend has it that Tisiphone's role extended beyond her duties as an avenger, as she fell in love with Cithaeron. However, their love story ended tragically when Tisiphone's affection led to Cithaeron's demise by snakebite.",
    description_3:
      "This depiction of Tisiphone encapsulates the artist's fascination with the intertwining themes of beauty, passion, and retribution found within mythological narratives. ",
    brand: "allDrawings",
    category: "home-screen-gallery-the-furies",
    height: 59,
    width: 42,
    materials: "ink, acrylic, ballpoint pen, collage",
    image: "/assets/The-furies/Tisiphone_by_meyoko-frame.jpg",
    image2: "/assets/The-furies/TisiphoneByMeyoko_frame.jpg",
    image3: "/assets/The-furies/Tisiphone.jpg",
    collection: "the-furies",

    tags: [
      {
        id: 0,
        label: "drawing",
      },
      {
        id: 1,
        label: "watercolor",
      },
      {
        id: 2,
        label: "theFuries",
      },
      {
        id: 3,
        label: "collage",
      },
      {
        id: 4,
        label: "mythology",
      },
      {
        id: 5,
        label: "tisiphone",
      },
    ],
  },
  {
    _id: "2",
    extraUrlName: "the-furies-Megaera",
    name: "Megaera",
    artCreationDate: "2012",
    description:
      "Megaera emerges from the depths of Greek mythology, a formidable figure veiled in the essence of jealousy and retribution. Known as one of the Erinyes or Furies, her name whispers tales of divine vengeance.",
    description_2:
      "Legend tells of Megaera's birth, born from the crimson cascade of Uranus' blood when Cronus, in his rebellion, severed the ties of sky and earth. Alongside her sisters Alecto and Tisiphone, she embodies the primal forces of justice, ensuring that the scales remain balanced even in the face of defiance.",
    description_3:
      "Within the artist's rendering, Megaera's presence is imbued with an aura of simmering resentment and righteous anger. Each stroke of the brush tells a story of betrayal punished and justice restored.",
    brand: "allDrawings",
    category: "whatever",
    height: 59,
    width: 42,
    materials: "ink, acrylic, ballpoint pen, collage",
    image: "/assets/The-furies/Megaera_by_meyoko-frame.jpg",
    image2: "/assets/The-furies/MegaeraByMeyoko_frame.jpg",
    image3: "/assets/The-furies/MEGAERA.jpg",

    collection: "the-furies",
    // ASPECT ratio used only on tagPage
    aspectRatio: "15/9",

    // ---------
    tags: [
      {
        id: 0,
        label: "drawing",
      },
      {
        id: 1,
        label: "watercolor",
      },
      {
        id: 2,
        label: "theFuries",
      },
      {
        id: 3,
        label: "collage",
      },
      {
        id: 4,
        label: "mythology",
      },
      {
        id: 5,
        label: "megaera",
      },
    ],
  },
  //
  //-----------------
  // 🧁 FLORA & Fauna 🧁
  //-----------------
  //
  {
    _id: "3",
    extraUrlName: "flora-flora",
    name: "Flora",
    artCreationDate: "2017",
    description:
      "Khloris was a Nymph who was associated with spring, flowers and new growth,believed to have dwelt in the Elysian Fields.",
    brand: "allDrawings",
    category: "home-screen-gallery-the-fauna-e-flora",
    height: 59,
    width: 42,
    materials: "ink, watercolor, acrylic",
    image: "/assets/imgDrawings/Flora-meyoko_frame.jpg",
    image2: "/assets/imgDrawings/florabyMeyoko__frame.jpg",
    image3: "/assets/imgDrawings/Flora-meyoko.jpg",
    imgGalleryLgSection: "/assets/imgDrawings/Flora-meyoko.jpg",

    collection: "flora and fauna",

    tags: [
      {
        id: 0,
        label: "drawing",
      },
      {
        id: 1,
        label: "watercolor",
      },
      {
        id: 2,
        label: "fashion",
      },
      {
        id: 3,
        label: "flora",
      },
    ],
  },
  {
    _id: "4",
    extraUrlName: "notus",
    name: "Notus",
    artCreationDate: "2017",
    description:
      "Notus, the Greek god of the South Wind, symbolized the scorching breeze that intensified with the rise of Sirius after midsummer. Revered for its desiccating heat, Notus bore the responsibility of ushering in the storms during late summer and autumn. ",
    description_2:
      "Regarded as a feared force, he was often associated with the ominous role of a crop destroyer, casting a shadow over the agricultural landscape.",
    description_3:
      "In the ancient tales, Notus emerges as a powerful and enigmatic deity, weaving his influence through the changing seasons and leaving an indelible mark on the natural world.",
    brand: "allDrawings",
    category: "home-screen-gallery-the-fauna-e-flora",
    height: 59,
    width: 42,
    materials: "gold, watercolor, ink",
    image: "/assets/imgDrawings/Notos_by_meyoko_frame.jpg",
    image2: "/assets/imgDrawings/notosByMeyoko__frame.jpg",
    image3: "/assets/imgDrawings/Notos_meyoko.jpg",
    imgGalleryLgSection: "/assets/imgDrawings/Notos_meyoko.jpg",

    collection: "fauna and flora",

    tags: [
      {
        id: 0,
        label: "drawing",
      },
      {
        id: 1,
        label: "watercolor",
      },
      {
        id: 2,
        label: "fashion",
      },
      {
        id: 3,
        label: "fauna",
      },

      {
        id: 4,
        label: "collage",
      },
    ],
  },
  //
  //-----------------
  // indiv drawings
  //-----------------
  {
    _id: "5",
    extraUrlName: "neon",
    name: "neon",
    artCreationDate: "05.10.2013",
    description: "NEON",
    description_2: " ",
    description_3: " ",
    brand: "allDrawings",
    category: "whatever",
    height: 59,
    width: 42,
    materials: "ink",
    image: "/assets/img-archiv/neon_by_meyoko-frame-bl.jpg",
    image2: "/assets/img-archiv/neon.jpg",
    imgGalleryLgSection: "/assets/books-magazines/lamina_mag.jpg",
    collection: "debutt",
    tags: [
      {
        id: 0,
        label: "ballpoint pen",
      },
      {
        id: 1,
        label: "drawing",
      },
      {
        id: 2,
        label: "archive",
      },
    ],
  },
  //
  //-----------------
  //   SKULLS memento mori
  //-----------------

  {
    _id: "6",
    // 🟥 will not be included in meyoko creative site
    extraUrlName: "skull-memento-mori-Pomponia-Imperatoria",
    name: "Pomponia",
    artCreationDate: "05.10.2013",
    description:
      "Pomponia Imperatoria was crafted specifically for the 'Skull Appreciation Day' show curated by Noah Scalin and Vanessa Ruiz at The International Museum of Surgical Science. Commissioned to use a provided template, I designed this digital piece as a tribute to cicadas and their symbolic associations with resurrection, immortality, spiritual realization, and spiritual ecstasy. The intention was to create a work that could be cut apart and recombined with other artists' contributions in the exhibition.",
    description_2:
      "The group’s genus Magicicada comes from the Greek word Magi, meaning magic. The lifecycle and metamorphosis of this cricket was observed by ancient Greeks, Chinese and Mayas",
    description_3:
      "In Chinese folklore, the cicada is revered as a sacred animal symbolizing resurrection. During the Chou Dynasty, funeral rituals incorporated cicada-shaped funeral jades, carefully positioned on the tongues of the deceased. It was believed that this practice could usher in resurrection or reincarnation.",
    brand: "allDrawings",
    category: "homescreen-gallery-skull",
    height: 59,
    width: 42,
    materials: "ink, digital collage",
    image: "/assets/skulls/pomponiaImperatoria_meyoko_frame-black.jpg",
    image2: "/assets/skulls/0_Pomponia__frame.jpg",
    image3: "/assets/skulls/pomponiaImperatoria_meyoko-low-r.jpg ",
    imgGalleryLgSection: "/assets/skulls/pomponiaImperatoria_meyoko-low-r.jpg",
    collection: "memento",
    tags: [
      {
        id: 0,
        label: "digital",
      },
      {
        id: 1,
        label: "mixed-media",
      },
      {
        id: 2,
        label: "drawing",
      },
      {
        id: 3,
        label: "skulls",
      },
      {
        id: 4,
        label: "memento-mori",
      },
      {
        id: 5,
        label: "exhibition",
      },
      {
        id: 6,
        label: "museum",
      },
    ],
  },
  {
    _id: "7",
    // 🟥 will not be included in meyoko creative site
    extraUrlName: "skull-memento-mori-Anamnesis",
    name: "Anamnesis",
    artCreationDate: "07.20.2016",
    description:
      "Anamnesis, a concept central to Plato's epistemological and psychological theory as explored in Meno and Phaedo, suggests that humans possess knowledge from past incarnations",
    description_2:
      "In Phaedo, Plato introduces a way of life aimed at transcending the deceptive influence of the body through katharsis (Greek: κάθαρσις; “cleansing” or “purification”).The body and its senses, perceived as sources of error, necessitate a purification process.  Plato contends that true knowledge can be rediscovered within us through reason, engaging in contemplation with the soul (noesis). ",
    description_3:
      "This philosophical perspective serves as a thematic backdrop, exploring the interplay between the ephemeral nature of the body and the enduring essence of knowledge within the soul.",
    brand: "allDrawings",
    category: "homescreen-gallery-skull",
    height: 59,
    width: 42,
    materials: "ink, digital collage",
    image: "/assets/skulls/anamnesis_anacridium-frame-bl.jpg",
    image2: "/assets/skulls/anamnesis_anacridium-low-r.jpg",
    image3: "/assets/skulls/anamnesisblack_meyoko-low-r.jpg",

    //
    imgGalleryLgSection: "/assets/skulls/anamnesis_anacridium-low-r.jpg",
    collection: "memento",
    tags: [
      {
        id: 0,
        label: "digital",
      },
      {
        id: 1,
        label: "mixed-media",
      },
      {
        id: 2,
        label: "drawing",
      },
      {
        id: 3,
        label: "skulls",
      },
      {
        id: 4,
        label: "memento-mori",
      },
    ],
  },
  {
    _id: "8",
    extraUrlName: "skull-memento-mori-Acherontia",
    name: "Acherontia",
    artCreationDate: "07.20.2016",
    description:
      "Inspired by the fusion of beauty in death, as seen in 'The Most Beautiful Dead' by Koudounaris and the blending of skeletons with masks in Maison Martin Margiela's haute couture A/W12, my goal was to evoke a harmonious interplay of life and art in my portrayal.",
    brand: "allDrawings",
    category: "whatever",
    height: 59,
    width: 42,
    materials: "ink, digital collage",
    image: "/assets/skulls/AcherontiaAtropus_meyoko_frame-bl.jpg",
    image2: "/assets/skulls/0_Acherontia_frame.jpg",
    image3: "/assets/skulls/AcherontiaAtropus_meyoko_low-r.jpg",
    imgGalleryLgSection: "/assets/skulls/AcherontiaAtropus_meyoko_low-r.jpg",
    collection: "memento",
    tags: [
      {
        id: 0,
        label: "digital",
      },
      {
        id: 1,
        label: "mixed-media",
      },
      {
        id: 2,
        label: "drawing",
      },
      {
        id: 3,
        label: "skulls",
      },
      {
        id: 4,
        label: "memento-mori",
      },
    ],
  },
  {
    _id: "9",
    extraUrlName: "skull-memento-mori-Anacridium-2",
    name: "Anacridium no.2",
    artCreationDate: "12.04.12",
    description:
      "Anacridium no.2 echoes the delicate equilibrium inherent in our surroundings, weaving together the subtleties of Nepenthes plants and the diverse marine life. The nurturing behaviors of Enteroctopus dofleini provide a poignant metaphor, highlighting the interconnected balance that exists within the natural world.",
    description_2:
      "These influences harmonize, creating a reflective exploration of the intricate dance of life and nature's perpetual balance.",
    brand: "allDrawings",
    category: "whatever",
    height: 59,
    width: 42,
    materials: "ink, digital collage",
    image: "/assets/skulls/anacridium2_meyoko-frame-bl.jpg",
    image2: "/assets/skulls/anacridium2balck_meyoko-low-r.jpg",
    image3: "/assets/skulls/anacridium2_meyoko-low-r.jpg",
    image4: "/assets/skulls/0_anacridium2_black_frame.jpg",
    imgGalleryLgSection: "/assets/skulls/anacridium2_meyoko-low-r.jpg",
    collection: "memento",

    tags: [
      {
        id: 0,
        label: "digital",
      },
      {
        id: 1,
        label: "mixed-media",
      },
      {
        id: 2,
        label: "drawing",
      },
      {
        id: 3,
        label: "skulls",
      },
      {
        id: 4,
        label: "memento-mori",
      },
    ],
  },
  {
    _id: "10",
    extraUrlName: "skull-memento-mori-AlausOculatus",
    name: "Alaus Oculatus",
    artCreationDate: "01.05.12",
    brand: "allDrawings",
    category: "whatever",
    height: 59,
    width: 42,
    materials: "ink, digital collage",
    image: "/assets/skulls/Alausoculatus_meyoko-frame-bl.jpg",
    image2: "/assets/skulls/0_AlausOculatos_frame.jpg",
    image3: "/assets/skulls/Alausoculatus_meyoko-low-r.jpg",
    imgGalleryLgSection: "/assets/skulls/Alausoculatus_meyoko-low-r.jpg",
    collection: "memento",

    tags: [
      {
        id: 0,
        label: "digital",
      },
      {
        id: 1,
        label: "mixed-media",
      },
      {
        id: 2,
        label: "drawing",
      },
      {
        id: 3,
        label: "skulls",
      },
      {
        id: 4,
        label: "memento-mori",
      },
    ],
  },
  {
    _id: "11",
    extraUrlName: "skull-memento-mori-Pazuzu",
    name: "Pazuzu",
    artCreationDate: "05.29.2012",
    description:
      "Pazuzu is renowned as the demon associated with the southwest wind, notorious for inducing famine during dry seasons and unleashing locusts during periods of rainfall.",
    description_2:
      "While Pazuzu is inherently an malevolent spirit, his paradoxical nature reveals a unique aspect of his character.",
    description_3:
      "Despite his malevolence, Pazuzu is recognized for driving away other evil spirits. In doing so, he becomes a guardian figure, actively protecting humans from plagues and misfortunes.",

    materials: "ink, digital collage",
    image: "/assets/skulls/Pazuzu_meyoko-frame-bl.jpg",
    image2: "/assets/skulls/0_Pazuzu__frame.jpg",
    image3: "/assets/skulls/Pazuzu_meyoko-low-r.jpg",
    //
    imgGalleryLgSection: "/assets/skulls/Pazuzu_meyoko-low-r.jpg",

    brand: "allDrawings",
    category: "homescreen-gallery-skull",
    height: 59,
    width: 42,
    collection: "memento",
    tags: [
      {
        id: 0,
        label: "digital",
      },
      {
        id: 1,
        label: "mixed-media",
      },
      {
        id: 2,
        label: "drawing",
      },
      {
        id: 3,
        label: "skulls",
      },
      {
        id: 4,
        label: "memento-mori",
      },
    ],
  },
  {
    _id: "12",
    extraUrlName: "skull-memento-mori-Anacridium-1",
    name: "Anacridium no.1",
    artCreationDate: "11.08.2011",
    description:
      "Anacridium, a genus of tree locusts or bird grasshoppers in the subfamily Cyrtacanthacridinae, stands as a focal point in the Memento Mori collection. This series intertwines insects, marine life, plants, and mythology, exploring symbolism with a profound emphasis on death",
    materials: "ink, digital collage",
    image: "/assets/skulls/anacridium_meyoko-frame-bl.jpg",
    image2: "/assets/skulls/0_Anacridium_frame.jpg",
    image3: "/assets/skulls/anacridiumblack_meyoko-low-r.jpg",
    image4: "/assets/skulls/anacridium_meyoko.jpg",
    imgGalleryLgSection: "/assets/skulls/Pazuzu_meyoko-low-r.jpg",
    brand: "allDrawings",
    category: "whatever",
    height: 59,
    width: 42,
    collection: "memento",
    tags: [
      {
        id: 0,
        label: "digital",
      },
      {
        id: 1,
        label: "mixed-media",
      },
      {
        id: 2,
        label: "drawing",
      },
      {
        id: 3,
        label: "skulls",
      },
      {
        id: 4,
        label: "memento-mori",
      },
    ],
  },
  {
    _id: "13",
    extraUrlName: "skull-memento-mori-semiotus",
    name: "Semiotus",
    artCreationDate: "07.13.2012",
    shopBtnUrl: "https://meyokoillustrations.blogspot.com/",
    description:
      "Inspired by the fusion of beauty in death, as seen in 'The Most Beautiful Dead' by Koudounaris and the blending of skeletons with masks in Maison Martin Margiela's haute couture A/W12, my goal was to evoke a harmonious interplay of life and art in my portrayal.",
    brand: "allDrawings",
    category: "whatever",
    height: 59,
    width: 42,
    materials: "ink, digital collage",
    image: "/assets/skulls/semiotus-by-meyoko-frame-bl.jpg",
    image2: "/assets/imgDrawings/semiotus_wh_low-res_meyoko.jpg",
    image3: "/assets/skulls/semiotus-black-by-melissamurillo-meyoko.jpg",
    imgGalleryLgSection: "/assets/imgDrawings/semiotus_wh_low-res_meyoko.jpg",
    imgMagazine_1: "/assets/books-magazines/lamina_mag.jpg",
    collection: "memento",

    tags: [
      {
        id: 0,
        label: "digital",
      },
      {
        id: 1,
        label: "mixed-media",
      },
      {
        id: 2,
        label: "drawing",
      },
      {
        id: 3,
        label: "fashion",
      },
      {
        id: 4,
        label: "skulls",
      },
    ],
  },
  //-----------------
  // DANAE garden
  //-----------------
  {
    _id: "14",
    extraUrlName: "the-danae-garden",
    name: "Danae",
    artCreationDate: "07.13.2012",
    description:
      "The story of Princess Danaë is rooted in ancient Greek mythology. Secluded within the walls of her father's fortress in Argos, Danaë's tale unfolds amidst the backdrop of divine intervention and prophetic destiny. It is a narrative steeped in the rich tapestry of Greek lore, where gods and mortals intertwine, shaping the course of human existence with their actions and desires..",
    brand: "allDrawings",
    category: "danae",
    height: 59,
    width: 42,
    materials: "ink, digital collage",
    image: "/assets/theDanaeGarden/1-danae-detail_melissamurillo.jpg",
    image2: "/assets/theDanaeGarden/1-danae-detail_melissamurillo.jpg",
    // below: avatar that will be shown when redirected to the TAG page
    image3: "/assets/theDanaeGarden/2-danae-detail_melissamurillo.jpg",
    image4: "/assets/theDanaeGarden/3-danae-detail-melissamurillo.jpg",
    //LG
    imgGalleryLgSection:
      "/assets/theDanaeGarden/3-danae-detail-melissamurillo.jpg",

    // mag imgs
    imgMagazine_1: "/assets/books-magazines/lamina_mag.jpg",

    collection: "gardens",

    tags: [
      {
        id: 0,
        label: "drawing",
      },
      {
        id: 1,
        label: "gardens",
        categoryTag: "gardens",
      },
      {
        id: 2,
        label: "danae",
        categoryTag: "danae",
      },
      {
        id: 3,
        label: "mythology",
      },
    ],
  },

  //-----------------
  // Republic of Utopia
  //-----------------
  {
    _id: "15",
    extraUrlName: "the-republic-of-utopia",
    name: "The Republic",
    artCreationDate: "07.13.2012",
    description:
      "Meyoko's 'The Republic of Utopia' is a visual homage to the fantastical realm depicted in G. Garfield Crimmins' 'The Republic of Dreams.'",
    description_2:
      "Crimmins' book conjures an island republic situated in the Mid-Atlantic, a place where dreamers, artists, eccentrics, and poets find refuge from the constraints of common sense. In this vibrant dream landscape, love, wisdom, and creativity flourish, each corner of the island commemorated by artistic inspiration, philosophical musings, and poetic insights.",
    description_3:
      "Through 'The Republic of Utopia,' Meyoko reimagines Crimmins' visionary world, offering a visual interpretation of its themes of liberty, love, and poetry. In Meyoko's rendition, viewers are transported to a realm where the boundaries of reality blur, inviting them to explore the transformative power of creativity and the pursuit of utopian ideals.",
    brand: "allDrawings",
    category: "republic",
    height: 59,
    width: 42,
    materials: "ink, digital collage",
    // below: avatar that will be shown when redirected to the TAG page
    image: "/assets/the-republic-of-utopia/republic-utopia-detail4.jpg",
    image2: "/assets/the-republic-of-utopia/republic-utopia-detail4.jpg",
    image3: "/assets/the-republic-of-utopia/6-republic-utopia-detail.jpg",
    imgGalleryLgSection:
      "/assets/the-republic-of-utopia/5-republic-utopia-detail.jpg",

    // mag imgs
    imgMagazine_1: "/assets/books-magazines/lamina_mag.jpg",

    collection: "gardens",

    tags: [
      {
        id: 0,
        label: "drawing",
      },
      {
        id: 1,
        label: "gardens",
        categoryTag: "gardens",
      },
      {
        id: 2,
        label: "republic",
        categoryTag: "republic",
      },
    ],
  },

  //-----------------
  // JULUNGGUL garden
  //-----------------
  {
    _id: "16",
    extraUrlName: "julunggul",
    name: "Julunggul",
    artCreationDate: "07.13.2012",
    description:
      "The drawing takes inspiration from two distinct mythological narratives: that of Julunggul, the Aboriginal Goddess, and Lilith from Jewish folklore.Julunggul, revered as the Rainbow Snake of the Dreamtime, symbolizes the primal forces of rain, water, and initiation.",

    description_2:
      "Through the drawing, Meyoko intertwines these narratives, exploring the duality of creation and repression. Julunggul's fluidity merges with Lilith's defiance, creating a tapestry of mythological depth that speaks to the complexities of feminine power and societal norms.",

    brand: "allDrawings",

    category: "whatever",
    height: 59,
    width: 42,
    materials: "ink, digital collage",
    // below: avatar that will be shown when redirected to the TAG page
    image: "/assets/img-archiv/julunggul_bymeyoko_frame-low.jpg",
    image2: "/assets/img-archiv/julunggul_bymeyoko_low.jpg",
    image3: "/assets/img-archiv/julunggul_bymeyoko_frame.jpg",

    imgGalleryLgSection: "/assets/img-archiv/julunggul.jpg",

    // mag imgs
    imgMagazine_1: "/assets/books-magazines/lamina_mag.jpg",

    collection: "gardens",

    tags: [
      {
        id: 0,
        label: "drawing",
      },
      {
        id: 1,
        label: "gardens",
      },
      {
        id: 2,
        label: "julunggul",
      },
      {
        id: 3,
        label: "mythology",
      },
    ],
  },

  //-----------------
  // the tree
  //-----------------
  {
    _id: "17",
    extraUrlName: "the-tree-of-life",
    name: "The Tree",
    artCreationDate: "2013",
    description:
      "Meyoko's 'Tree of Life' draws inspiration from the enigmatic marvel nestled in the heart of Bahrain's desert. This ancient mesquite tree, standing resilient for over four centuries, defies the harsh desert environment, captivating visitors from around the globe with its enduring presence.",
    description_2:
      "Named aptly for its profound symbolism, the 'Tree of Life' embodies the magic of existence itself. Legends intertwine with the site, with local inhabitants believing it to be the former site of the Garden of Eden.",
    description_3:
      "Situated amidst the barren expanse of the desert, the Tree of Life stands as a solitary sentinel, a verdant oasis amidst the arid landscape. From afar, it is a beacon of greenery, offering a glimpse of life's resilience in the midst of adversity.",

    brand: "allDrawings",
    category: "garden",
    height: 59,
    width: 42,
    materials: "ink, digital collage",
    // below: avatar that will be shown when redirected to the TAG page
    image: "/assets/the-tree-of-life/Thetree-detail-kopf.jpg",
    image2: "/assets/the-tree-of-life/Thetree-detail-kopf.jpg",
    image3: "/assets/the-tree-of-life/Thetree--detail-center-bottom.jpg",
    image4: "/assets/the-tree-of-life/Thetree-detail-bottom.jpg",
    //the entire drawing
    imgGalleryLgSection: "/assets/the-tree-of-life/Thetree-oflife.jpg",

    // mag imgs
    imgMagazine_1: "/assets/books-magazines/lamina_mag.jpg",

    collection: "gardens",

    tags: [
      {
        id: 0,
        label: "drawing",
      },
      {
        id: 1,
        label: "gardens",
      },
      {
        id: 2,
        label: "the-tree",
      },
    ],
  },
  //-----------------
  // simurgh
  //-----------------
  {
    _id: "18",
    extraUrlName: "the-simurgh",
    name: "The Simurgh",
    artCreationDate: "2013",
    description:
      " Meyoko's 'Simurgh' draws inspiration from the mystical tales of a legendary creature known as the Simurgh. Unlike some of its counterparts in mythology, renowned for their trickery, the Simurgh is revered for its benevolence and healing powers.",
    description_2:
      "According to Persian myth, the Simurgh resided in the Tree of Knowledge, an ancient symbol rich with symbolism. As the Simurgh took flight, the force of its ascent shook the tree's branches so vigorously that seeds from every plant ever known were scattered throughout the world, bestowing upon humanity a bounty of valuable flora.",

    brand: "allDrawings",
    category: "whatever",
    height: 59,
    width: 42,
    materials: "ink, digital collage",
    // below: avatar that will be shown when redirected to the TAG page
    image: "/assets/imgDrawings/simurgh-1-by-meyoko-frame-bl.jpg",
    //imgs within the drawingScreen page

    image2: "/assets/imgDrawings/Simurgh_no2_melissamurillo-meyoko.jpg",
    image3: "/assets/imgDrawings/Simurghg1_by_meyoko_frame.jpg",
    // mag imgs
    imgMagazine_1: "/assets/books-magazines/lamina_mag.jpg",

    collection: "heads",

    tags: [
      {
        id: 0,
        label: "drawing",
      },
      {
        id: 1,
        label: "heads",
      },
      {
        id: 2,
        label: "simurgh",
      },
      {
        id: 3,
        label: "mythology",
      },
    ],
  },
  //-----------------
  // ISHTAR 2 LG
  //-----------------
  {
    _id: "19",
    extraUrlName: "lilith",
    name: "Lilith",
    artCreationDate: "2013",
    description:
      "As Adam's initial companion, Lilith refused to be confined by conventional roles, challenging the patriarchal norms of her time. Her tale unfolds as a narrative of empowerment, as she embraces her autonomy and demands equality in the divine realm.",
    description_2:
      "Lilith's expulsion from Paradise is not a tale of defeat but a radiant assertion of her individuality. Draped in defiance, she symbolizes the unyielding spirit of feminism, inspiring generations to reject limitations and embrace their inherent strength.",

    brand: "allDrawings",
    category: "whatever",
    height: 59,
    width: 42,
    materials: "ink, digital collage",
    // below: avatar that will be shown when redirected to the TAG page
    image: "/assets/imgDrawings/lilith2_by_meyoko-frame-bl.jpg",
    //imgs within the drawingScreen page
    image2: "/assets/imgDrawings/lilith2_grand-by_meyoko.jpg",
    image3: "/assets/imgDrawings/lilith-by-melissamurillo-meyoko.jpg",
    image4: "/assets/imgDrawings/lilith-moleskine-meyoko.jpg",
    // mag imgs  lilith-by-melissamurillo-meyoko.jpg
    imgMagazine_1: "/assets/books-magazines/lamina_mag.jpg",

    collection: "lilith",

    tags: [
      {
        id: 0,
        label: "drawing",
      },
      {
        id: 1,
        label: "moleskine",
      },
      {
        id: 2,
        label: "lilith",
      },
      {
        id: 3,
        label: "mythology",
      },
      {
        id: 4,
        label: "sketchbook",
      },
    ],
  },
];

export default allDataMeyoko;
