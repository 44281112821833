// ------ FRAMER
//
import { motion, AnimatePresence } from "framer-motion";

//
//
const FadeBlurScaleOnEnter = ({ children }) => {
  //

  return (
    <>
      <AnimatePresence>
        <motion.div
          exit={{
            //   y: -20,
            scale: 2,
            opacity: 0,

            transition: {
              ease: "easeInOut",
              duration: 2,

              delay: 0.5,
              // ease: "easeIn", duration: 0.22
            },
          }}
          initial={{
            opacity: 0,
            filter: "blur(5px)",
            scale: 2,
            y: -15,
          }}
          animate={{
            opacity: 1,
            //   y: 0,
            scale: 1.1,
            filter: "blur(0px)",

            transition: {
              ease: "easeInOut",
              duration: 1.2,

              delay: 0.5,
              // type: "spring", duration: 0.7
            },
          }}
        >
          {children}
        </motion.div>
      </AnimatePresence>
    </>
  );
};

export default FadeBlurScaleOnEnter;
