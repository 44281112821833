import { useParams } from "react-router-dom";
import DragZoomImg from "../AnimationProps/DragZoomImg";
import collectionsMeyoko from "../../data_CollectionsData.js";

import { motion } from "framer-motion";

import "./styles-largImg-collection.scss";

const Index = ({ img }) => {
  //
  //
  const { id } = useParams();
  const drawingItemArch = collectionsMeyoko.find((p) => p._id === id);

  return (
    <>
      <div className="largeImg-wrapper-collection">
        <motion.div className="imgBox-drawingScreen__archive">
          <DragZoomImg>
            <motion.img
              className="img-lg-drawingScreenArch"
              src={img}
              alt={drawingItemArch.nameDrawingAltCollection}
            />
          </DragZoomImg>

          <div className="zoom-google-icon"></div>
        </motion.div>
      </div>
    </>
  );
};

export default Index;
