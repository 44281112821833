import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Aos from "aos";
import "aos/dist/aos.css";
import "./styles-patternScreen.scss";
import allPrintsMeyoko from "../data__AllMeyoko.js";
import { HeadlinePatterOpt2 } from "../headlineComponents/index.js";
import { DividerMsMedium, DividersSm } from "../components";
//
const PatternScreen = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const { ref, inView } = useInView();
  const animationOnviewHookH2 = useAnimation();

  useEffect(() => {
    if (inView) {
      animationOnviewHookH2.start({
        scale: 1,

        transition: {
          ease: [0.6, 0.01, -0.05, 0.95],
          duration: 1.8,

          delay: 0.9,
        },
      });
    }

    if (!inView) {
      animationOnviewHookH2.start({
        scale: 1.5,
      });
    }
  }, [inView, animationOnviewHookH2]);

  const { id } = useParams();

  const PatternItemArch = allPrintsMeyoko.find((p) => p._id === id);

  return (
    <section id="pattern-Screen">
      <div className="wrapper___pattern-Screen">
        <HeadlinePatterOpt2
          titlePatt={PatternItemArch.title}
          subTitlePatt1="&#x2724;"
          subTitlePatt2={PatternItemArch.spanTitle}
        />
        <DividersSm />

        <div className="container-pattern1">
          <motion.div ref={ref} className="containerGrid-pattern1">
            <motion.div className="container-card-pattern1">
              <div className="img-container-pattern1">
                <div className="container-imgBox-pattern1">
                  <motion.div className="img-boxPattern-pattern1">
                    <motion.img
                      animate={animationOnviewHookH2}
                      whileHover={{
                        scale: 1.1,

                        transition: {
                          duration: 0.6,
                          staggerChildren: 0.1,
                        },
                      }}
                      src={PatternItemArch.coverImg}
                      alt={PatternItemArch.altDrawingScreen}
                    />
                  </motion.div>
                </div>
              </div>

              <div className="wrapper__Description_patternScreen">
                <div className="wrapper-text__patternScreen">
                  <div className="container-text__patternScreen">
                    <h1
                      data-aos="fade-down-right"
                      data-aos-easing="linear"
                      data-aos-duration="700"
                    >
                      &#x2724;
                    </h1>
                    {PatternItemArch.spanTitle && (
                      <h2
                        data-aos="fade-down-right"
                        data-aos-easing="linear"
                        data-aos-duration="850"
                      >
                        {PatternItemArch.spanTitle}
                        <span>{PatternItemArch.subTitle}</span>
                      </h2>
                    )}

                    {PatternItemArch.artCreationDate && (
                      <h3
                        data-aos="fade-down-right"
                        data-aos-easing="linear"
                        data-aos-duration="1400"
                      >
                        {PatternItemArch.artCreationDate}
                      </h3>
                    )}

                    {PatternItemArch.descPattern1 && (
                      <p
                        data-aos="fade-down"
                        data-aos-easing="linear"
                        data-aos-duration="1600"
                      >
                        <span className="underlineP-pattern">
                          {PatternItemArch.descSpanUnderlinePattern0}
                        </span>
                        {PatternItemArch.descPattern1}
                        <span className="underlineP-pattern">
                          {PatternItemArch.descSpanUnderlinePattern1}
                        </span>
                        {PatternItemArch.descPattern2}
                        <span className="underlineP-pattern">
                          {PatternItemArch.descSpanUnderlinePattern2}
                        </span>
                        {PatternItemArch.descPattern3}
                        <span className="underlineP-pattern">
                          {PatternItemArch.descSpanUnderlinePattern3}
                        </span>
                        {PatternItemArch.descPattern4}
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div ref={ref} className="wrapper__galleryPatternScreen">
                <div className="container__galleryPatternScreen">
                  <ul className="container-imagesPatternScreen-ul">
                    {PatternItemArch.imgsCollectPattern.map((iPattern) => (
                      <li
                        key={iPattern.id}
                        className="img-containerPatternScreen"
                      >
                        <div className="containerPatternScreen_img-box">
                          <div className="img-boxPatternScreen">
                            <motion.img
                              src={iPattern.imgDraw_1}
                              alt={iPattern.altPattern_1}
                              whileHover={{
                                scale: 1.1,

                                transition: {
                                  duration: 0.6,
                                  staggerChildren: 0.1,
                                },
                              }}
                            />
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </motion.div>
          </motion.div>
        </div>
      </div>
      <DividerMsMedium />
      <HeadlinePatterOpt2 titlePatt="&#x2724;" />
    </section>
  );
};

export default PatternScreen;
