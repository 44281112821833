import { useEffect } from "react";

import { motion } from "framer-motion";

import Aos from "aos";
import "aos/dist/aos.css";

const ClipPathDropdownImg = ({ children }) => {
  useEffect(() => {
    Aos.init({ duration: 800 });
  }, []);

  return (
    <>
      <motion.div>
        <motion.div
          initial={{
            clipPath: "polygon(0 0, 100% 0, 100% 0, 0 0)",

            transition: { duration: 0.6 },
          }}
          animate={{
            clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",

            transition: { duration: 0.6, staggerChildren: 0.1 },
          }}
          exit={{
            clipPath: "polygon(0 100%, 100% 100%, 100% 100%, 0 100%)",

            transition: { duration: 0.6 },
          }}
        >
          {children}
        </motion.div>
      </motion.div>
    </>
  );
};

export default ClipPathDropdownImg;
