import "./LgImgCircleComp.scss";
import "aos/dist/aos.css";
import { motion } from "framer-motion";
import ScaleInTransition from "../AnimationProps/ScaleIN-transition-enter-page";

const Index = ({ img }) => {
  return (
    <motion.div className="wrapperImg__CircleComp">
      <div className="container-circleImg">
        {" "}
        <div
          className="containerImg__CircleComp"
          data-aos="fade-down-right"
          data-aos-easing="linear"
          data-aos-duration="800"
        >
          <div className="img-box__CircleComp">
            <ScaleInTransition>
              <motion.img
                drag
                whileDrag={{ scale: 1.4 }}
                dragConstraints={{
                  top: -20,
                  right: 20,
                  bottom: 20,
                  left: -20,
                }}
                src={img}
                alt=""
              />
            </ScaleInTransition>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Index;
