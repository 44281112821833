import React from "react";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import allPrintsMeyoko from "../../data__AllMeyoko.js";
import GridSimurgh from "./CollectionSimurgh/GridSimurgh";

import "./styles-allPrints-page2.scss";
import { HeadlinePatterOpt2 } from "../../headlineComponents/index.js";
import { DividerMsMedium, FooterPrintsSvg } from "../../components/index.js";

const Index = () => {
  return (
    <section id="allPrints_page2">
      <div className="allPrints_page2">
        <HeadlinePatterOpt2
          titlePatt="&#x2724;"
          subTitlePatt1="Die"
          subTitlePatt2="Blumen"
        />

        <div className="wrapper_gallery_grid_3">
          <div className="container_gallery-card_grid-3">
            <ul className="container-images-and-text_3">
              {allPrintsMeyoko
                .filter(
                  (product) =>
                    product.category === "home-screen-gallery-noCollection"
                )
                .map((itemSkull) => (
                  <li key={`${itemSkull._id}`} className="img-container-grid-2">
                    <NavLink
                      style={{ cursor: "none" }}
                      to={`/folio-drawing/${itemSkull._id}/${itemSkull.extraUrlName}`}
                    >
                      <div className="container_img-box-grid-2">
                        <div className="img-box-grid-2">
                          <motion.img
                            src={itemSkull.coverImg}
                            alt={itemSkull.altDrawingScreen}
                            whileHover={{
                              scale: 1.1,
                              transition: {
                                duration: 0.6,
                                staggerChildren: 0.1,
                              },
                            }}
                          />
                        </div>
                      </div>
                    </NavLink>
                  </li>
                ))}
            </ul>
          </div>
        </div>

        <GridSimurgh allPrintsMeyoko={allPrintsMeyoko} />
      </div>

      <DividerMsMedium />
      <HeadlinePatterOpt2 titlePatt="&#x2724;" />
      <DividerMsMedium />
      <FooterPrintsSvg />
    </section>
  );
};

export default Index;
