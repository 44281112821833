import { useState, Fragment } from "react";
import { useMediaQuery } from "react-responsive";
import { DeviceSize } from "../../../react-responsive";
// data
import collectionsMeyoko from "../../../data_CollectionsData.js";
//comps
import ProjectData from "./DataProject";
import ModalHover from "../modalHover/ModalHover";
//
//
import "./style-projectmodal.scss";

const Index = () => {
  //
  const isLaptop = useMediaQuery({ maxWidth: DeviceSize.laptop });
  const isDesktop = useMediaQuery({ maxWidth: DeviceSize.desktop });
  //
  //

  const [modal, setModal] = useState({ active: false, id: 0 });

  //
  //
  return (
    <>
      {isLaptop || isDesktop ? (
        <div
          // style={{ position: "relative" }}
          className="wrapper-project-modal"
        >
          {collectionsMeyoko.map((item, _id) => (
            <Fragment key={item._id}>
              <ProjectData
                id={item._id}
                title={item.name}
                setModal={setModal}
                extraUrlName={item.extraUrlName}
                artCreationDate={item.artCreationDate}
              />
            </Fragment>
          ))}

          <div
            style={{
              position: "absolute",
              top: "-40%",
              left: "40%",
              right: "0",
              bottom: "100%",
            }}
          >
            <ModalHover modal={modal} collectionsMeyoko={collectionsMeyoko} />
          </div>
        </div>
      ) : (
        <div
          // style={{ position: "relative" }}
          className="wrapper-project-modal"
        >
          {collectionsMeyoko.map((item, _id) => (
            <Fragment key={item._id}>
              <ProjectData
                id={item._id}
                title={item.name}
                setModal={setModal}
                extraUrlName={item.extraUrlName}
                artCreationDate={item.artCreationDate}
              />
            </Fragment>
          ))}

          {/* MODAL img & cursor */}
        </div>
      )}
    </>
  );
};

export default Index;
