const dataPress = [
  {
    __id: "1",
    extraUrlName:
      "gestalten-book-Beautiful-illustrations-for-fashion-and-style",
    title: "'Beautiful illustrations for fashion and style'",
    subTitle: " | ",
    //
    productCountry: "Germany",
    publishingDate: "2010",
    companyBrand: "GESTALTEN",
    companyPublisher: "",
    //
    //p desc
    descPress1:
      "The Beautiful is a stunning collection of work by 100 of today's trendiest and most promising fashion and lifestyle illustrators. As one of the most versatile disciplines, illustration has been increasingly used by magazines and other publications to enrich content and by advertisers to address target audiences more precisely.",
    descPress2:
      "The Book was edited by Anneke Krull (the editor of 'i love illustration') and features sought-after talents including:",
    descPress3:
      "Autumn Whitehurst, Cecilia Carlstedt, Daniel Egneus, Ed Tsuwaki, Gabriel Moreno, James Dignan, Jenny Mörtsell, Jules Julien, Julie Verhoeven, Kareem Iliya, Meyoko, Lina Bodén, Linn Olofsdotter, Liselotte Watkins, Makiko Sugawa, Margot Macé, My Dead Pony, Piet Paris, Ricardo Fumanal, Sara Singh, Stina Persson, Tina Berning, and Will Broome and more.",
    //img's
    coverImg: "/assets/publications/gestalten_00.png",
    //imgs
    imgPress1: "/assets/publications/gestalten_00.png",
    imgPress2: "/assets/publications/gestalten1.jpg",
    imgPress3: "/assets/publications/gestalten__02.jpg",
    imgPress4: "/assets/publications/gestalten-book.gif",

    // LINKS  ArtQuarter_by_DPI_magazine-taiwan_5.jpg
    linkPress1: "https://gestalten.com/",
    linkPress2: "https://www.iloveillustrationgallery.com/exhibitions",
    linkPress3: "",
    alt: "images of meyoko's published drawings for Gestalten Germany",
    // all press
    category: "press",
    // related posts
    relatedPost: "editorial",
    //
    // Title color
    headlineTitleLgTop: "#000000",
    svgSymbolColor: "#000000",
    //
    //-----------
    // Colors & font
    backgroundColor: "#fdfbf3",
    lgTitle: "#000000",
    smTitle: "#000000",
    paragraphDescription: "#121212",
    // -----------
    // BTN'S colors
    bgButton: "#0d0d0d",
    bgHoverButton: "transparent",
    colorFontButton: "#000000",
    colorHoverFontButton: "#f1e5cfd6",
    // btn small icon (attached to the btn)
    backgroundSMBtnAttached: "#fdfbf3",

    pressTags: [
      {
        id: 0,
        label: "editorial",
      },
      {
        id: 1,
        label: "book",
      },
    ],
  },
  {
    __id: "2",
    extraUrlName: "kurv-magazine",
    title: "'Kurv Issue 17'",
    subTitle: " | ",
    //
    productCountry: "Australia",
    publishingDate: "2013",
    companyBrand: "kurv magazine",
    companyPublisher: "Barnes and Noble",
    //
    //p desc
    descPress1:
      "Kurv. Magazine is an internationally distributed fashion, beauty, culture, art and style magazine. Kurv Magazine prides itself on the preservation of photographs and simplistic design style.",
    descPress2:
      "Featuring stars such as Brooke Shields, Galliano, Karl Lagerfeld and more, Kurv Magazine exudes luxury and sophistication, captivating fashion enthusiasts across the globe.",
    descPress3:
      "Brook Shields Cougar. By Tony Duran Brioni Chan Christian Dior Couture Christian Lacroix Dior Homme Giorgio Armani Prive John Galliano Karl Lagerfeld La Perla Roberto Cavalli...",
    //img's
    coverImg: "/assets/publications/kurv-au_0.jpg",
    //imgs
    imgPress1: "/assets/publications/kurv-au_0.jpg",
    imgPress2: "/assets/publications/kurv-au_1-photo.jpg",
    imgPress3: "/assets/publications/kurv-au_2-photo.jpg",
    imgPress4: "/assets/publications/kurv-au_3-photo.jpg",

    // LINKS
    linkPress1: "https://www.fashionmodeldirectory.com/magazines/kurv/",
    // linkPress2: "https://www.facebook.com/dpimagazinetw/",
    // linkPress3: "",
    alt: "images of meyoko's published drawings for Kurv magazine Australia",
    // all press
    category: "press",
    // related posts
    relatedPost: "editorial",
    //
    // Title color
    headlineTitleLgTop: "#000000",
    svgSymbolColor: "#000000",
    //
    //-----------
    // Colors & font
    backgroundColor: "#fdfbf3",
    lgTitle: "#000000",
    smTitle: "#000000",
    paragraphDescription: "#121212",
    // -----------
    // BTN'S colors
    bgButton: "#0d0d0d",
    bgHoverButton: "transparent",
    colorFontButton: "#000000",
    colorHoverFontButton: "#f1e5cfd6",
    // btn small icon (attached to the btn)
    backgroundSMBtnAttached: "#fdfbf3",

    pressTags: [
      {
        id: 0,
        label: "editorial",
      },
      {
        id: 1,
        label: "magazine",
      },
    ],
  },
  {
    __id: "3",
    extraUrlName: "art-quarter-by-dpi",
    title: "'Art Quarter vol 2'",
    subTitle: " | ",
    //
    productCountry: "Taiwan",
    publishingDate: "2013",
    companyBrand: "dpi magazine",
    companyPublisher: "",
    //
    //p desc
    descPress1: "Art Quarter vol 2 Meticulous ART II,",
    descPress2:
      "DPI is a leading design publication from Taiwan that showcases “imaginative and artistically innovative photography, film and art.",
    // descPress3:" ",
    //img's
    coverImg: "/assets/publications/ArtQuarter_by_DPI_magazine-taiwan_0.jpg",
    //imgs
    imgPress1: "/assets/publications/ArtQuarter_by_DPI_magazine-taiwan_1.jpg",
    imgPress2: "/assets/publications/ArtQuarter_by_DPI_magazine-taiwan_2.jpg",
    imgPress3: "/assets/publications/ArtQuarter_by_DPI_magazine-taiwan_3.jpg",
    imgPress4: "/assets/publications/ArtQuarter_by_DPI_magazine-taiwan_5.jpg",

    // LINKS  ArtQuarter_by_DPI_magazine-taiwan_5.jpg
    linkPress1: "https://www.dpi.media/",
    linkPress2: "https://www.facebook.com/dpimagazinetw/",
    linkPress3: "",
    alt: "images of meyoko's published drawings for DPI magazine taiwan",
    // all press
    category: "press",
    // related posts
    relatedPost: "editorial",
    //
    // Title color
    headlineTitleLgTop: "#000000",
    svgSymbolColor: "#000000",
    //
    //-----------
    // Colors & font
    backgroundColor: "#fdfbf3",
    lgTitle: "#000000",
    smTitle: "#000000",
    paragraphDescription: "#121212",
    // -----------
    // BTN'S colors
    bgButton: "#0d0d0d",
    bgHoverButton: "transparent",
    colorFontButton: "#000000",
    colorHoverFontButton: "#f1e5cfd6",
    // btn small icon (attached to the btn)
    backgroundSMBtnAttached: "#fdfbf3",

    pressTags: [
      {
        id: 0,
        label: "editorial",
      },
      {
        id: 1,
        label: "book",
      },
    ],
  },
  {
    __id: "4",

    extraUrlName: "file-magazine",
    title: "'File magazine'",
    subTitle: " | ",
    //
    productCountry: "England",
    publishingDate: "2010",
    companyBrand: "File magazine",
    companyPublisher: "",
    //
    //p desc
    descPress1:
      "FILE Magazine is a publication featuring a broad selection of visual culture in the fields of graphic design, art, photography, fashion and moving image.",
    descPress2:
      "Beautifully presented in a 30 x 39 cm hard cover with a full colour 96 pages newsprint stitched inside.",
    descPress3:
      "Each issue is accompanied by a full-screen online player, Screening in full the issue’s short films, music videos and documentaries. A magazine to Watch & Read.",
    //img's
    coverImg: "/assets/publications/FILE-magazine__trans.png",
    //imgs
    imgPress1: "/assets/publications/FILE-magazine__00.jpg",
    imgPress2: "/assets/publications/FILE-magazine__01.jpg",
    imgPress3: "/assets/publications/FILE-magazine__02.jpg",
    imgPress4: "/assets/publications/FILE-magazine__03.jpg",

    // LINKS  ArtQuarter_by_DPI_magazine-taiwan_5.jpg
    linkPress1: "https://file-magazine.com/magazine/file-issue-3",
    // linkPress2: " ",
    // linkPress3: "",
    alt: "images of meyoko's published drawings for FILE magazine UK",
    // all press
    category: "press",
    // related posts
    relatedPost: "editorial",
    //
    // Title color
    headlineTitleLgTop: "#000000",
    svgSymbolColor: "#000000",
    //
    //-----------
    // Colors & font
    backgroundColor: "#fdfbf3",
    lgTitle: "#000000",
    smTitle: "#000000",
    paragraphDescription: "#121212",
    // -----------
    // BTN'S colors
    bgButton: "#0d0d0d",
    bgHoverButton: "transparent",
    colorFontButton: "#000000",
    colorHoverFontButton: "#f1e5cfd6",
    // btn small icon (attached to the btn)
    backgroundSMBtnAttached: "#fdfbf3",

    pressTags: [
      {
        id: 0,
        label: "editorial",
      },
      {
        id: 1,
        label: "magazine",
      },
    ],
  },
  {
    __id: "5",
    extraUrlName: "berlin-what-book",
    title: "'Berlin WHAT?'",
    subTitle: " | ",
    //
    productCountry: "Germany",
    publishingDate: "2014",
    companyBrand: "Neon-Chocolat-gallery",
    companyPublisher: "",
    //
    //p desc
    descPress1:
      "A Book on 102 Contemporary Artists in Berlin. The compilation, 'Berlin WHAT?' shows the work of both established and up-and-coming contemporary artists based in Berlin. ",
    descPress2:
      "Uwe Neu and Oliver Thoben, curators of Neonchocolate Gallery in Berlin, have published a book offering a personal snapshot of artists that they feel characterize the Berlin art scene. The compilation, Berlin What?, shows the work of both established and up-and-coming contemporary artists based in Berlin.",
    descPress3:
      "'In May 2010 we opened a gallery called Neonchocolate, a place for contemporary art that offered both young and established artists a platform for their work, irrespective of genre, status or curriculum vitae. The art was – and is – all that mattered..'",
    //img's
    coverImg: "/assets/publications/BerlinWhat__cover-2.jpg",
    //imgs
    imgPress1: "/assets/publications/BerlinWhat__1.jpg",
    imgPress2: "/assets/publications/BerlinWhat__2.jpg",
    imgPress3: "/assets/publications/BerlinWhat__3.jpg",
    // LINKS
    linkPress1:
      "http://www.berlinartlink.com/2013/11/05/berlin-what-a-book-on-102-contemporary-artists-in-berlin/",
    linkPress2: "",
    linkPress3: "",
    // ALT
    alt: "images of meyoko's published drawings for NEON chocolat gallery book Berlin WHAT",
    // all press
    category: "press",
    // related posts
    relatedPost: "editorial",
    //
    // Title color
    headlineTitleLgTop: "#000000",
    svgSymbolColor: "#000000",
    //
    //-----------
    // Colors & font
    backgroundColor: "#fdfbf3",
    lgTitle: "#000000",
    smTitle: "#000000",
    paragraphDescription: "#121212",
    // -----------
    // BTN'S colors
    bgButton: "#0d0d0d",
    bgHoverButton: "transparent",
    colorFontButton: "#000000",
    colorHoverFontButton: "#f1e5cfd6",
    // btn small icon (attached to the btn)
    backgroundSMBtnAttached: "#fdfbf3",

    pressTags: [
      {
        id: 0,
        label: "editorial",
      },
      {
        id: 1,
        label: "book",
      },
    ],
  },
  {
    __id: "6",
    extraUrlName: "lodown-magazine",
    title: "'LODOWN 73'",
    subTitle: " | ",
    //
    productCountry: "Germany",
    publishingDate: "2010",
    companyBrand: "Lodown magazine",
    companyPublisher: "",
    //
    //p desc
    descPress1:
      "'Lodown has established itself over the years as one of the best independent cultural magazine of our time . Besides its landscape format and the large visual work done on the layout , the German mag could be a combination of the best pages of American publications such as Juxtapoz and the Fader'text by WAD magazine",
    descPress2:
      "Featuring: Sofia Coppola, Matt Edwards, Team Macho, Joe Biel, The Dark, How To Dress Well, Jason Jaegel, Andy Votel, Meyoko, Kevin Van Braak and much more.",
    // descPress3:
    //   " ",

    //img's
    coverImg: "/assets/publications/lodown__0.png",
    //imgs
    imgPress1: "/assets/publications/lodown__0.png",
    // imgPress2: "/assets/publications/lodown__02.png",
    // imgPress3: "/assets/publications/lodown__03.png",
    // imgPress4: "/assets/publications/FILE-magazine__03.jpg",

    // LINKS  ArtQuarter_by_DPI_magazine-taiwan_5.jpg
    linkPress1: "http://lodownmagazine.com/",
    // linkPress2: " ",
    // linkPress3: "",
    alt: "images of meyoko's published drawings for LODOWN magazine",
    // all press
    category: "press",
    // related posts
    relatedPost: "editorial",
    //
    // Title color
    headlineTitleLgTop: "#000000",
    svgSymbolColor: "#000000",
    //
    //-----------
    // Colors & font
    backgroundColor: "#fdfbf3",
    lgTitle: "#000000",
    smTitle: "#000000",
    paragraphDescription: "#121212",
    // -----------
    // BTN'S colors
    bgButton: "#0d0d0d",
    bgHoverButton: "transparent",
    colorFontButton: "#000000",
    colorHoverFontButton: "#f1e5cfd6",
    // btn small icon (attached to the btn)
    backgroundSMBtnAttached: "#fdfbf3",

    pressTags: [
      {
        id: 0,
        label: "editorial",
      },
      {
        id: 1,
        label: "magazine",
      },
    ],
  },
  {
    __id: "7",
    extraUrlName: "beautiful-decay",
    title: "'The Underdogs'",
    subTitle: " | ",
    //
    productCountry: "USA",
    publishingDate: "2010",
    companyBrand: "Beautiful Decay",
    companyPublisher: "",
    //
    //p desc
    descPress1:
      "Beautiful/Decay began as a humble, black and white, DIY photocopied ‘zine while founder Amir H. Fallah was just 16. Over time, the publication grew into a full color, internationally distributed magazine. Today, Beautiful/Decay takes the form of a limited edition, hand numbered, advertising free art book series.",
    descPress2:
      "Though Beautiful/Decay has grown into an internationally recognized design-driven lifestyle brand, the publication still carries that same youthful sense of rebellion and experimentation that influenced the very first issues.",
    descPress3:
      "The Underdogs” features over 100+ submissions from around the world interpreting the book’s title. Each piece was created uniquely for this issue of Beautiful/Decay, and was not been displayed anywhere else before its release!",

    //img's
    coverImg: "/assets/publications/beautiful-decay_00.jpg",
    //imgs
    imgPress1: "/assets/publications/beautiful-decay_00.jpg",
    imgPress2: "/assets/publications/beautiful-decay_01.png",
    imgPress3: "/assets/publications/beautiful-decay_02.jpg",
    // imgPress4: "/assets/publications/FILE-magazine__03.jpg",

    // LINKS  ArtQuarter_by_DPI_magazine-taiwan_5.jpg
    linkPress1: "http://www.beautifuldecay.com/publication/",
    // linkPress2: " ",
    // linkPress3: "",
    alt: "images of meyoko's published drawings for Beautiful Decay Usa",
    // all press
    category: "press",
    // related posts
    relatedPost: "editorial",
    //
    // Title color
    headlineTitleLgTop: "#000000",
    svgSymbolColor: "#000000",
    //
    //-----------
    // Colors & font
    backgroundColor: "#fdfbf3",
    lgTitle: "#000000",
    smTitle: "#000000",
    paragraphDescription: "#121212",
    // -----------
    // BTN'S colors
    bgButton: "#0d0d0d",
    bgHoverButton: "transparent",
    colorFontButton: "#000000",
    colorHoverFontButton: "#f1e5cfd6",
    // btn small icon (attached to the btn)
    backgroundSMBtnAttached: "#fdfbf3",

    pressTags: [
      {
        id: 0,
        label: "editorial",
      },
      {
        id: 1,
        label: "book",
      },
    ],
  },
  {
    __id: "8",
    extraUrlName: "lindstrom-and-prins-thomas",
    title: "'Lindstrom & Prins Thomas 2'",
    subTitle: " | ",
    //
    productCountry: "Norway",
    publishingDate: "2009",
    companyBrand: "Eskimo records",
    companyPublisher: "",
    //
    //p desc
    descPress1:
      "A musical collaboration between Norwegian producers Hans-Peter Lindstrøm and Prins Thomas. Their style of music is often termed 'space disco' with influences including electro, fusion, prog and krautrock.",
    descPress2:
      "The Norwegian cosmonauts first made waves together in 2005 with their self-titled debut for Eskimo Recordings, which melded amorphous instrumental rock with their admiration for disco's funky pulse. It was followed by 'Reinterpretations' (2007) and 'II' (2009). ",
    descPress3:
      "They both have backgrounds in different Norwegian band projects, ranging from punk to gospel to heavy metal to folk and psychedelia, but they also shared a passion for collecting records and run their own labels Feedelity, Full Pupp and Internasjonal, while also releasing on Smalltown Supersound. (text by Eskimo Records official)",

    //img's
    coverImg: "/assets/publications/lindstrom-prinsthomas-bymeyoko.jpg",
    //imgs
    imgPress1: "/assets/publications/lindstrom_cover.jpg",
    imgPress2: "/assets/publications/lindstrom-prinsthomas_bw2.jpg",
    imgPress3: "/assets/publications/lindstrom-prinsthomas_bw1.jpg",
    imgPress4: "/assets/publications/cover-lindstrom1.jpg",
    imgPress5: "/assets/publications/coverlindstrom-2.jpg",
    imgPress6: "/assets/publications/lindstrom-theguardian.jpg",
    // LINKS  ArtQuarter_by_DPI_magazine-taiwan_5.jpg
    linkPress1: "https://eskimorecordings.bandcamp.com/album/ii",
    linkPress2: "https://en.wikipedia.org/wiki/Hans-Peter_Lindstr%C3%B8m",
    linkPress3: "https://en.wikipedia.org/wiki/Prins_Thomas",
    alt: "images of meyoko's published drawings for FILE magazine UK",
    // all press
    category: "press",
    // related posts
    relatedPost: "editorial",
    //
    // Title color
    headlineTitleLgTop: "#000000",
    svgSymbolColor: "#000000",
    //
    //-----------
    // Colors & font
    backgroundColor: "#fdfbf3",
    lgTitle: "#000000",
    smTitle: "#000000",
    paragraphDescription: "#121212",
    // -----------
    // BTN'S colors
    bgButton: "#0d0d0d",
    bgHoverButton: "transparent",
    colorFontButton: "#000000",
    colorHoverFontButton: "#f1e5cfd6",
    // btn small icon (attached to the btn)
    backgroundSMBtnAttached: "#fdfbf3",

    pressTags: [
      {
        id: 0,
        label: "editorial",
      },
      {
        id: 1,
        label: "album",
      },
      {
        id: 2,
        label: "vinyl",
      },
      {
        id: 3,
        label: "conspiration",
      },
    ],
  },
  {
    __id: "9",
    extraUrlName: "territory-magazine-sg",
    title: "'Territory 12, Crests of Creativ'",
    subTitle: " | ",
    //
    productCountry: "Singapore",
    publishingDate: "2010",
    companyBrand: "File magazine",
    companyPublisher: "",
    //
    //p desc
    descPress1:
      "Territory 12, Crests of Creative, In riding the crests of creativity to greater artistic heights and breakthroughs, we in this epoch have been fortunate to bear witness to the continuous emergence of original ways to communicate and express ideas, information and feelings to document the human experience.",
    descPress2:
      "This fertile creative richness, harnessed by designers and artists alike, has given birth to new inventions and innovation in design, as well as artworks that are a testament to brilliance and unlimited nature of the human imagination.",
    descPress3:
      "Featuring: Clayton Brothers, Marumiyam, Ana Benaroya, Reza Abedini, Fantasista Utamaro, Design Hero, Muzika, Si Scott, Meyoko, Leeroy Vanilla, Harvest, We Are Start, Thijs bakker, Anita Cheng and Lisa Lee.",

    //img's
    coverImg: "/assets/publications/territory-magazine-cover.jpg",
    //imgs
    imgPress1: "/assets/publications/territory-magazine-cover.jpg",
    imgPress2: "/assets/publications/territory_01.jpg",
    imgPress3: "/assets/publications/territory_02.jpg",
    // imgPress4: "/assets/publications/FILE-magazine__03.jpg",

    // LINKS  ArtQuarter_by_DPI_magazine-taiwan_5.jpg
    linkPress1:
      "https://lastgasp.com/products/territory-volume-12-international-designers-territory",
    // linkPress2: " ",
    // linkPress3: "",
    alt: "images of meyoko's published drawings for FILE magazine UK",
    // all press
    category: "press",
    // related posts
    relatedPost: "editorial",
    //
    // Title color
    headlineTitleLgTop: "#000000",
    svgSymbolColor: "#000000",
    //
    //-----------
    // Colors & font
    backgroundColor: "#fdfbf3",
    lgTitle: "#000000",
    smTitle: "#000000",
    paragraphDescription: "#121212",
    // -----------
    // BTN'S colors
    bgButton: "#0d0d0d",
    bgHoverButton: "transparent",
    colorFontButton: "#000000",
    colorHoverFontButton: "#f1e5cfd6",
    // btn small icon (attached to the btn)
    backgroundSMBtnAttached: "#fdfbf3",

    pressTags: [
      {
        id: 0,
        label: "editorial",
      },
      {
        id: 1,
        label: "book",
      },
    ],
  },

  {
    __id: "10",
    extraUrlName: "saltbeat",
    title: "Saltbeat",
    subTitle: " | ",
    //
    productCountry: "Greece",
    publishingDate: "2015",
    companyBrand: "SaltBeat",
    companyPublisher: "",
    //
    //p desc
    descPress1:
      "Inspired by her love of kiteboarding, contemporary design and fashion, Despina Pistiolis launched Saltbeat, a label of luxury waterwear based on sophisticated tailoring, technical functionality and an ever-evolving collection fueled by seasonal collaborations with upcoming artists and designers from around the world. ",

    descPress2:
      "'Saltbeat is a womenswear label of luxury waterwear fueled by love of kitesurfing.'",
    descPress3:
      "In collaboration with Berlin based illustrator Meyoko, Russian tattoo artist Diana Katsko and Greek graphic designer Stavros Kypraios, the first Saltbeat collection features eye-popping colors and hand drawn sultry prints, complemented by a core range of sophisticated black and silver pieces of true “esprit de la mer” aesthetics. ",
    //img's
    coverImg: "/assets/publications/saltbeat__00.jpg",
    //imgs
    imgPress1: "/assets/publications/G_300406069555_320_ZP_1.jpg",

    imgPress2: "/assets/publications/G_300406069555_320_ZP_3.jpg",

    imgPress3:
      "/assets/publications/Saltbeat-collaboration_melissamurillo-meyoko.jpg",
    // links
    linkPress1:
      "https://www.thegreekfoundation.com/fashion/saltbeat-luxury-waterwear-collection",
    alt: "images of meyoko for saltbeat apparel",

    // all press
    category: "press",
    // related posts
    relatedPost: "apparel",
    //
    // Title color
    headlineTitleLgTop: "#fdfbf3",
    //
    svgSymbolColor: "#fdfbf3",
    //-----------
    // Colors & font

    // grid
    backgroundColor: "#010100",
    lgTitle: "#fdfbf3",
    smTitle: "#fdfbf3",
    paragraphDescription: "#f1e5cfd6",
    // -----------
    // BTN'S colors
    bgButton: "#fdfbf3",
    bgHoverButton: "transparent",
    colorFontButton: "#f1e5cfd6",
    colorHoverFontButton: "#010100",
    // btn small icon (attached to the btn)
    backgroundSMBtnAttached: "#010100",

    //
    pressTags: [
      {
        id: 0,
        label: "apparel",
      },
      {
        id: 1,
        label: "pattern",
      },
      {
        id: 2,
        label: "fashion",
      },
      {
        id: 3,
        label: "collaboration",
      },
    ],
  },
  {
    __id: "11",
    extraUrlName: "chickenshit",
    title: "Chickenshit",
    subTitle: " | ",
    //
    productCountry: "Russia",
    publishingDate: "2010",
    companyBrand: "Chickenshit",
    companyPublisher: "Chickenshit",
    //
    //p desc
    descPress1:
      "SAVE YOUR NOSSSE IS collaborative Fashion label producing handkerchiefs in collaboration with famous photographers, designers , illustrators from all over the world",
    // descPress2:
    //   "Chickenshit is the Russian-based creative community and the fashion brand, established in 2006. Originally created as a non-for-profit label, today Chickenshit is available for international wholesale.Chickenshit collections are constructed in a process where creative graphics, strong concept and dark mood are the main inspiration.",
    descPress3:
      "The Saint Petersburg based brand was established in 2009 by one of the designers of (CSC).The main idea that every handkerchief is exclusive and made in style of the artist .",
    //img's
    coverImg: "/assets/publications/SAVE-YOUR-NO.jpg",
    //imgs
    // imgPress1: "/assets/publications/SYN_01.jpg",
    imgPress2: "/assets/publications/meyoko-for-SYN1.jpg",
    imgPress3: "/assets/publications/SYN_01.jpg",
    // links
    // linkPress1: "",
    // linkPress2: "",
    // linkPress3: "",
    alt: "images of meyoko for Chickenshit apparel",

    // all press
    category: "press",
    // related posts
    relatedPost: "apparel",
    //
    // Title color

    headlineTitleLgTop: "#000000",
    svgSymbolColor: "#000000",
    //-----------
    //-----------
    // Colors & font
    // red bg

    backgroundColor: "#fdfbf3",
    lgTitle: "#000000",
    smTitle: "#000000",
    paragraphDescription: "#121212",
    // -----------
    // BTN'S colors
    bgButton: "#0d0d0d",
    bgHoverButton: "transparent",
    colorFontButton: "#000000",
    colorHoverFontButton: "#f1e5cfd6",
    // btn small icon (attached to the btn)
    backgroundSMBtnAttached: "#d10000",
    //
    pressTags: [
      {
        id: 0,
        label: "apparel",
      },
      {
        id: 1,
        label: "pattern",
      },
      {
        id: 2,
        label: "fashion",
      },
      {
        id: 3,
        label: "collaboration",
      },
      {
        id: 4,
        label: "the-tree",
      },
    ],
  },
  {
    __id: "12",
    extraUrlName: "thokkThokk",
    title: "ThokkThokk",
    subTitle: " | ",
    //
    productCountry: "Germany",
    publishingDate: "2011",
    companyBrand: "ThokkThokk",
    companyPublisher: "",
    //
    //p desc
    descPress1:
      "THOKKTHOKK has chosen to create and market ethical clothing made from organic agriculture. Specializing in the production of T-shirts, this manufacturer uses organic cotton grown in Turkey and India.",
    descPress2:
      "The entire processing and manufacturing phase takes place in these same countries to limit ecological impact, in a fair manner according to the standards of GOTS certification.Printing is then done in Germany at their facilities in small batches. THOKKTHOKK exclusively uses water-based inks, non-polluting and environmentally friendly, certified by Oeko-Tex 100.",
    descPress3:
      "Plastic packaging is biodegradable without PVC, and cardboard packaging is 100% recyclable. Their goal is to offer ethical and organic items at a reasonable price, ensuring that no one is disadvantaged.",
    //img's
    coverImg: "/assets/publications/meyoko_for_ThokkThokk_01.jpg",
    //imgs
    imgPress1: "/assets/publications/meyoko_for_ThokkThokk_01.jpg",
    imgPress2: "/assets/publications/meyoko_for_ThokkThokk_02.jpg",
    imgPress3: "/assets/publications/meyoko_for_ThokkThokk_03_.jpg",
    // LINKS
    linkPress1: "https://www.facebook.com/thokkthokk?locale=fr_FR",
    linkPress2: "",
    linkPress3: "",
    alt: "images of meyoko for THOKKTHOKK apparel",
    // all press
    category: "press",
    // related posts
    relatedPost: "apparel",
    //
    //
    // Title color
    headlineTitleLgTop: "#000000",
    svgSymbolColor: "#000000",
    //

    //-----------
    // Colors & font
    backgroundColor: "#fdfbf3",
    lgTitle: "#000000",
    smTitle: "#000000",
    paragraphDescription: "#121212",
    // -----------
    // BTN'S colors
    bgButton: "#0d0d0d",
    bgHoverButton: "transparent",
    colorFontButton: "#000000",
    colorHoverFontButton: "#f1e5cfd6",
    // btn small icon (attached to the btn)
    backgroundSMBtnAttached: "#fdfbf3",
    //
    //
    //
    pressTags: [
      {
        id: 0,
        label: "apparel",
      },
      {
        id: 1,
        label: "fashion",
      },
      {
        id: 2,
        label: "collaboration",
      },
      {
        id: 3,
        label: "conspiration",
      },
    ],
  },
  {
    __id: "13",
    extraUrlName: "soft-gallery",
    title: "Soft Gallery",
    subTitle: " | ",
    //
    productCountry: "Denmark",
    publishingDate: "2013",
    companyBrand: "Soft Gallery",
    companyPublisher: "",
    //
    //p desc
    descPress1:
      "Designer Tina Holt Miller and fashion photographer and stylist Barbara Hvidt are behind Soft Gallery, founded in 2007.",
    descPress2:
      "Their selection of clothing has been created in collaboration with local and international artists, whose illustrations and artwork specially translated into Soft Gallery's clothes.",
    descPress3:
      "Barbara Hvidt has worked as a photographer, concept developer and stylist for a number of top international brands such as Vivienne Westwood, Nokia, Laura Biagiotti, Selfridges, Cover Magazine, Costume and Eurowoman.",
    //img's
    coverImg: "/assets/publications/soft-gallery-imagebymintstudio.jpg",
    //imgs
    imgPress1: "/assets/publications/softgallery-0_collaget.jpg",
    imgPress2: "/assets/publications/softgallery-0_collaget_opt2.jpg",
    imgPress3: "/assets/publications/Alecto_tshirt_2.jpg",
    // LINKS
    linkPress1: "https://soft-gallery.com/",
    linkPress2: "",
    linkPress3: "",
    alt: "images of meyoko for Soft Gallery apparel",
    // all press
    category: "press",
    // related posts
    relatedPost: "apparel",
    //
    // Title color
    headlineTitleLgTop: "#000000",
    svgSymbolColor: "#000000",
    //
    //-----------
    // Colors & font
    backgroundColor: "#fdfbf3",
    lgTitle: "#000000",
    smTitle: "#000000",
    paragraphDescription: "#121212",
    // -----------
    // BTN'S colors
    bgButton: "#0d0d0d",
    bgHoverButton: "transparent",
    colorFontButton: "#000000",
    colorHoverFontButton: "#f1e5cfd6",
    // btn small icon (attached to the btn)
    backgroundSMBtnAttached: "#fdfbf3",

    pressTags: [
      {
        id: 0,
        label: "apparel",
      },
      {
        id: 1,
        label: "fashion",
      },
      {
        id: 2,
        label: "collaboration",
      },
    ],
  },
];
export default dataPress;
// beautiful-decay_00.jpg
