import { useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";

import collectionsMeyoko from "../../data_CollectionsData";

import DragZoomImg from "../AnimationProps/DragZoomImg";

import { motion } from "framer-motion";

import Aos from "aos";
import "aos/dist/aos.css";

import "./styles-gallery2X.scss";

const Index = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const { id } = useParams();
  const drawingItemArch = collectionsMeyoko.find((p) => p._id === id);

  return (
    <>
      <div className="gallery-container-collection__2X">
        <div className="gallery-lg-card-container-collection__2X">
          <>
            {drawingItemArch.itemDrawingCollection1
              ? drawingItemArch.itemDrawingCollection1.map((e) => (
                  <Fragment key={e.id}>
                    <ul className="card-gallery-collection__2X">
                      <li className="img-container-gallery-collection__2X">
                        <div className="img-box-gallery-collection__2X">
                          {e.imgDrawing ? (
                            <DragZoomImg>
                              <motion.img
                                src={e.imgDrawing}
                                alt={e.imgDrawing}
                              />
                            </DragZoomImg>
                          ) : (
                            ""
                          )}
                        </div>
                      </li>
                    </ul>
                  </Fragment>
                ))
              : ""}
          </>
        </div>
      </div>
    </>
  );
};

export default Index;
