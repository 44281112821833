import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import "./GridSimurgh.scss";

const GridSimurgh = ({ allPrintsMeyoko }) => {
  return (
    <>
      <div className="wrapper_gallery_grid_4">
        <div className="container_gallery-card_grid-4">
          <ul className="container-images-and-text_4">
            {allPrintsMeyoko
              .filter(
                (product) =>
                  product.category === "home-screen-gallery-simurgh-collection"
              )
              .map((itemSkull) => (
                <li key={`${itemSkull._id}`} className="img-container-grid-4">
                  <NavLink
                    style={{ cursor: "none" }}
                    to={`/folio-drawing/${itemSkull._id}/${itemSkull.extraUrlName}`}
                  >
                    <div className="container_img-box-grid-4">
                      <div className="img-box-grid-4">
                        <motion.img
                          src={itemSkull.coverImg}
                          alt={itemSkull.altDrawingScreen}
                          whileHover={{
                            scale: 1.1,

                            transition: { duration: 0.6, staggerChildren: 0.1 },
                          }}
                        />
                      </div>
                    </div>
                  </NavLink>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default GridSimurgh;
