import { useLocation } from "react-router-dom";
import { useEffect } from "react";

import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import { GrabAndDrag3DImg } from "../../components";

import Aos from "aos";
import "aos/dist/aos.css";

import "./headlineWelcome.scss";

const Index = ({
  //🤚
  activeSection,
  titleCompWelcomeProp,
  subTitleCompWelcomeProp,
  titleCollectionsmall,
  subTitleCollectionsmall,
}) => {
  const location = useLocation();

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const { ref, inView } = useInView();
  const animationOnviewHook = useAnimation();
  const animationOnviewHookH1 = useAnimation();

  useEffect(() => {
    if (inView) {
      animationOnviewHook.start({
        y: 0,
        transition: {
          type: "spring",
          duration: 1,
          damping: 16,
          delay: 0.08,
        },
      });

      animationOnviewHookH1.start({
        y: 0,
        transition: {
          type: "spring",
          duration: 1,
          damping: 13,
          delay: 0.4,
        },
      });
    }

    if (!inView) {
      animationOnviewHook.start({
        y: 250,
      });

      animationOnviewHookH1.start({
        y: 750,
      });
    }
    //
  }, [inView, animationOnviewHook, animationOnviewHookH1]);

  return (
    <motion.div ref={ref} id="wrapper_headlineSection_welcome">
      <div
        id="container-headline-welcome-grid"
        className={
          location.pathname === "/" && activeSection === "section-3"
            ? "is--containerHeadline-section_welcome-white"
            : "is--containerHeadline-section_welcome-black"
        }
      >
        <div id="container-welcome-left">
          <GrabAndDrag3DImg>
            <motion.h1
              id={
                location.pathname === "/" && activeSection === "section-3"
                  ? "is--h1-section_welcome-white"
                  : "is--h1-section_welcome-black"
              }
              className={
                location.pathname === "/" && activeSection === "section-3"
                  ? "is-line-1_welcome-white"
                  : "is-line-1_welcome-black"
              }
            >
              {titleCompWelcomeProp}
            </motion.h1>
          </GrabAndDrag3DImg>

          <h1
            id={
              location.pathname === "/" && activeSection === "section-3"
                ? "is--h1-section_welcome-white"
                : "is--h1-section_welcome-black"
            }
            className={
              location.pathname === "/" && activeSection === "section-3"
                ? "is-line-2_welcome-white"
                : "is-line-2_welcome-black"
            }
          >
            <GrabAndDrag3DImg>
              <motion.span>{subTitleCompWelcomeProp}</motion.span>
            </GrabAndDrag3DImg>
          </h1>
        </div>

        <div id="container-welcome-right">
          <h1
            id={
              location.pathname === "/" && activeSection === "section-3"
                ? "is--h1-section_welcome-right-white"
                : "is--h1-section_welcome-right-black"
            }
          >
            {titleCollectionsmall}
          </h1>

          <h2
            id={
              location.pathname === "/" && activeSection === "section-3"
                ? "is--h2-section_welcome-right-white"
                : "is--h2-section_welcome-right-black"
            }
          >
            {subTitleCollectionsmall}
          </h2>

          <h3
            id={
              location.pathname === "/" && activeSection === "section-3"
                ? "is--h3-section_welcome-right-white"
                : "is--h3-section_welcome-right-black"
            }
          >
            01.01.24
          </h3>
        </div>
      </div>
    </motion.div>
  );
};

export default Index;
