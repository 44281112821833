import React from "react";

import { NavLink } from "react-router-dom";
import { useRandomNumberHook } from "./useRandomNumberHook";

import { motion } from "framer-motion";
import ClipPathDropdownImg from "../../components/AnimationProps/ClipPath-DropdownImg-on-enter";

const ImgMemento = ({ index, mediaMemento }) => {
  const minWidthMedia = index === 0 ? 30 : 40;
  const maxWidthMedia = index === 0 ? 5 : 40;

  const width = useRandomNumberHook({ min: minWidthMedia, max: maxWidthMedia });

  const marginLefti = useRandomNumberHook({ min: 0, max: 15 });

  return (
    <>
      <motion.div
        className="mediaMemento-wrapper"
        style={{ "--img-width": width, "--image-margin": marginLefti }}
      >
        <ul className="wrapperMemento-img-random-card">
          <li className="containerMemento-img-random">
            <NavLink
              to={`/folio-drawing/${mediaMemento._id}/${mediaMemento.extraUrlName}`}
            >
              <ClipPathDropdownImg>
                <motion.img
                  whileHover={{
                    scale: 1.1,

                    transition: {
                      duration: 0.6,
                      staggerChildren: 0.1,
                    },
                  }}
                  src={mediaMemento.image}
                  alt=""
                />
              </ClipPathDropdownImg>
            </NavLink>

            <div className="desc_drawing-memento-wrapper">
              <h1>
                {mediaMemento.name}
                <span className="desc_drawing-memento-date">
                  {mediaMemento.artCreationDate}
                </span>
              </h1>
            </div>
          </li>
        </ul>
      </motion.div>
    </>
  );
};

export default ImgMemento;
