import { useEffect, useRef, Fragment } from "react";
import "./styles-comp-modal.scss";
// ------ FRAMER
import { motion } from "framer-motion";
// ------ GSAP
import gsap from "gsap";
//
//
//

const ModalHover = ({ modal, collectionsMeyoko }) => {
  //
  //
  // here we are going to extract it
  //   - if the modal is "active" and then which one is active , then we can use this index or id to change the top , in other words we will do the css line but in js
  const { active, id } = modal;
  //
  //
  // ---- framer --------------
  const scaleAnimation = {
    initial: {
      //   borderRadius: "100%",
      //   opacity: 0,
      scale: 0,
      x: "-50%",
      y: "-50%",
    },
    // ** OPEN
    open: {
      //   borderRadius: "10%",
      //   opacity: 1,
      scale: 1,
      x: "-50%",
      y: "-50%",
      transition: {
        duration: 0.4,
        ease: [0.76, 0, 0.24, 1],
      },
    },
    // CLOSE
    closed: {
      //   borderRadius: "100%",
      //   opacity: 0,
      scale: 0,
      x: "-50%",
      y: "-50%",
      transition: {
        duration: 0.4,
        ease: [0.32, 0, 0.67, 0],
      },
    },
  };

  //
  // ---- gsap --------------
  const container = useRef(null);
  // ---- framer cursor ----
  //   framer cursor (this has nothing to do with the other framer animation related to the hover of the modal IMG)
  const cursor = useRef(null);
  const cursorLabel = useRef(null);
  //
  //
  useEffect(() => {
    // here we will tell it that we want to target the "container", specifically the "left" side
    const moveContainerX = gsap.quickTo(container.current, "left", {
      duration: 0.8,
      ease: "power3",
    });
    const moveContainerY = gsap.quickTo(container.current, "top", {
      duration: 0.8,
      ease: "power3",
    });
    // --------------
    //--- cursor ----
    //
    const moveCursorX = gsap.quickTo(cursor.current, "left", {
      duration: 0.5,
      ease: "power3",
    });
    const moveCursorY = gsap.quickTo(cursor.current, "top", {
      duration: 0.5,
      ease: "power3",
    });
    //
    //
    //--- cursorLabel ----
    const moveCursorLabelX = gsap.quickTo(cursorLabel.current, "left", {
      duration: 0.45,
      ease: "power3",
    });
    const moveCursorLabelY = gsap.quickTo(cursorLabel.current, "top", {
      duration: 0.45,
      ease: "power3",
    });
    //
    // ---------------
    //
    window.addEventListener("mousemove", (e) => {
      const { clientX, clientY } = e;
      moveContainerX(clientX);
      moveContainerY(clientY);
      //--- cursor ----
      moveCursorX(clientX);
      moveCursorY(clientY);
      //--- cursorLabel ----
      moveCursorLabelX(clientX);
      moveCursorLabelY(clientY);
    });
  }, []);
  //
  //

  //
  return (
    <>
      <div className="wrapper-modal">
        <motion.div
          // GSAP ----
          ref={container}
          //
          //  framer ----
          variants={scaleAnimation}
          initial={"initial"}
          //   when should we animate?
          // only when "active"
          animate={active ? "open" : "closed"}
          //  framer ----
          className="modalContainer"
        >
          <div style={{ top: id * -100 + "%" }} className="modalSlider">
            {collectionsMeyoko.map((project) => (
              <div
                // key={project._id}
                // key={`modal_${id}`}
                key={`modal_${id}_${project._id}`} // Unique key for each item
                className="modal-hover"
              >
                {/* const {src, color} = project; */}

                <img
                  src={project.imageLargeCollectionDebut1}
                  // src={`/images/${src}`}
                  width={200}
                  height={0}
                  alt="img drawing"
                />
              </div>
            ))}
          </div>
        </motion.div>
        {/* 👾

      CURSOR

      */}
        <motion.div ref={cursor} className="cursor"></motion.div>
        <motion.div ref={cursorLabel} className="cursorLabel">
          View
        </motion.div>
      </div>
    </>
  );
};

// Index = {
//   collectionsMeyoko: PropTypes.shape({
//     _id: PropTypes.string.isRequired,
//     imageLargeCollectionDebut1: PropTypes.string.isRequired,
//   }).isRequired,
// };

export default ModalHover;
