import Project from "./project";

//
//

const Index = () => {
  //
  //
  return (
    <>
      <Project />
    </>
  );
};

export default Index;
