import React, { useEffect, useRef } from "react";

// **👨🔴 the cabinet was before the tagPage i used to test the "random width size" , this cabinet will nest the republic, the three, an other extremeley detailed drawings, the will have a h1 title on black fat 400 > and an h2 subtitle fat 200 to 300 in grey
// ----------------------
// ** Data Drawings
// ----------------------
import allDataMeyoko from "../../data_AllDataMeyoko.js";

// ----------------------
// **   framer
// ----------------------
import { motion } from "framer-motion";
import ImgMemento from "./ImgMemento";

//
import { Pazuzu3dMemento } from "../../headlineComponents/indexImg";
import "./styles-mementoPage.scss";
//
//
const Memento = () => {
  //
  //----------- 3D card
  const banner3dRef = useRef(null);
  const cardRef = useRef(null);

  useEffect(() => {
    const handleMouseMove = (event) => {
      const x = event.pageX;
      const y = event.pageY;

      const target = cardRef.current;
      const targetCoords = target.getBoundingClientRect();
      const targetX = targetCoords.left + target.offsetWidth / 2;
      const targetY = targetCoords.top + target.offsetHeight / 2;

      const angleX = (targetY - y) / 185;
      const angleY = (targetX - x) / -185;

      target.style.transform =
        "rotateX(" + angleX + "deg) rotateY(" + angleY + "deg)";
    };

    const banner3d = banner3dRef.current;
    banner3d.addEventListener("mousemove", handleMouseMove);

    return () => {
      banner3d.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);
  //----------- 3D card

  // responsiveness of the 3d card
  // Calculate minHeight based on vmin or any other criteria you prefer
  const minHeight = `calc(50vmin + 100px)`; // Example
  //
  return (
    <>
      <section id="memento-page-wrapper">
        {/*

             3D card
       */}
        <div ref={banner3dRef} className="banner3d">
          <div
            ref={cardRef}
            className="card-memento"
            style={{
              "--min-height": minHeight,
              "--min-width": minHeight,
            }}
          >
            <div className="bannerImgBox">
              <img src={Pazuzu3dMemento} alt="" />
            </div>
          </div>
        </div>

        {/*

             Memento Description
       */}
        <div className="memento-page_container-description">
          <h1>
            memento <span className="memento-page-span">mori</span>
          </h1>

          <h2 className="memento-page-H2-desc">
            Memento Mori, a Latin phrase meaning 'Remember you must die',
            invites contemplation on mortality—a reflection not of fear, but of
            life's fleeting beauty.
          </h2>

          <p className="memento-page-paragraph_1">
            {/* Rooted in the ancient practice of memento mori, Meyoko's art delves into the essence of mortality with a poignant reflection on life's fleeting nature. Through meticulously detailed ballpoint pen sketches on Aquarelle paper, she channels the tribal face paints of the Ethiopian Omo Valley and Congolese Efe pygmy tribes. These practices, steeped in tradition and belief, evoke themes of protection and rebirth, echoing the urgency of our actions in the face of mortality.

            */}
            {/* As we delve into the concept of Memento Mori, we're reminded of
            life's transient nature, urging us to reflect on mortality with a
            sense of purpose. Meyoko's artistry, meticulously depicted through
            ballpoint pen sketches on Aquarelle paper, serves as a conduit for
            this introspection. Inspired by the protective tribal face paints of
            the Ethiopian Omo Valley and Congolese Efe pygmy tribes, Meyoko
            delves into themes of preservation and renewal. */}
            In this spirit, Meyoko's art delves into the ancient traditions of
            tribal face paints, drawing inspiration from the Ethiopian Omo
            Valley and Congolese Efe pygmy tribes. These rituals, steeped in
            symbolism and belief, speak to themes of protection and renewal,
            resonating with the artist's exploration of life's ephemeral nature.
          </p>

          <p className="memento-page-paragraph_2">
            Delving deeper, Meyoko found resonance in Egyptian beliefs on
            Immortality and the symbolism of the sacred beetle—a metaphor for
            resurrection and transformation. Yet, it was Paul Koudounaris's
            revelatory work on bejeweled skulls that ignited her imagination,
            merging opulence with religious motifs.
          </p>
          <p className="memento-page-paragraph_3">
            While watching the Martin Margiela haute couture A/W12 show,
            Meyoko's creative vision crystallized. Inspired by tribal traditions
            and the allure of religious iconography, she embarked on a journey
            to translate these influences into her own unique artistic
            expression.
          </p>
        </div>

        {/*  */}
        {/*

CAN BE USED for related posts, add a property relatedPost:"apparel",
   {allDataMeyoko
            .filter((itemMemento) => itemMemento.collection === "memento")
       */}

        <motion.div className="containerMemento-images-random">
          {allDataMeyoko
            .filter((itemMemento) => itemMemento.collection === "memento")
            .map((mediaMemento, index) => (
              <ImgMemento
                key={`${mediaMemento._id}`}
                mediaMemento={mediaMemento}
                index={index + 1}
              />
            ))}
        </motion.div>
      </section>
    </>
  );
};

export default Memento;
