//
// This SCALE on entering the page happens just once, only when we enter. I wil create another component,  in which the user will see the scale from 2 to 1 when on view, each time they enter or scroll to the section. Its not good to add too many animations

import { useEffect } from "react";
// ------ FRAMER
import { motion } from "framer-motion";
// ------ AOS
import Aos from "aos";
import "aos/dist/aos.css";
//
//
//
const ScaleInTransitionPage = ({ children }) => {
  //

  //
  const containerVariants = {
    //
    //  hidden
    hidden: {
      opacity: 0,
      scale: 2,
    },

    // visible
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        ease: [0.6, 0.05, 0.01, 0.95], // Corrected cubic bezier values
        duration: 1.2,
        delay: 0.5,
      },
      // transition: {
      //   ease: [0.6, 0.01, -0.05, 0.95],
      //   duration: 1.2,
      //   delay: 0.5,
      // },

      // transition: {
      //   delayChildren: 0.3,
      //   staggerChildren: 0.2,
      // },
    },
  };
  //

  //
  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  //
  //
  // ------ AOS -----
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  //
  //

  return (
    <>
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div
          variants={itemVariants}
          initial="hidden"
          animate="visible"
        />

        {children}
      </motion.div>
    </>
  );
};

export default ScaleInTransitionPage;
