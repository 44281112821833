import { useEffect } from "react";
import "./styles-headline-comps-props.scss";

import { useLocation } from "react-router-dom";

import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";

const Index = ({
  activeSection,

  titleHeadlineCompProp,
  subTitleHeadlineCompProp,

  titleHeadlineCompPropDate,
  spanHeadlineCompPropDate,
}) => {
  const location = useLocation();

  const { ref, inView } = useInView();
  const animationOnviewHook = useAnimation();
  // h1
  const animationOnviewHookH1 = useAnimation();
  // h3
  const animationOnviewHookH3 = useAnimation();
  //
  useEffect(() => {
    if (inView) {
      animationOnviewHook.start({
        y: 0,
        transition: {
          type: "spring",
          duration: 1,
          damping: 16,
          delay: 0.08,
        },
      });

      animationOnviewHookH1.start({
        y: 0,
        transition: {
          type: "spring",
          duration: 1,
          damping: 13,
          delay: 0.4,
        },
      });
      animationOnviewHookH3.start({
        y: 0,
        transition: {
          type: "spring",
          duration: 1,
          damping: 16,
          delay: 0.18,
        },
      });
    }

    if (!inView) {
      animationOnviewHook.start({
        y: 350,
      });

      animationOnviewHookH1.start({
        y: 350,
      });

      animationOnviewHookH3.start({
        y: 350,
      });
    }
    //
  }, [
    inView,
    animationOnviewHook,
    animationOnviewHookH1,
    animationOnviewHookH3,
  ]);

  return (
    <motion.div ref={ref} className="wrapper-headline-prop">
      <motion.h1
        animate={animationOnviewHookH1}
        className={
          location.pathname === "/" && activeSection === "section-3"
            ? "font-white-h1"
            : "font-black-h1"
        }
      >
        {titleHeadlineCompProp}
      </motion.h1>

      <motion.h2
        animate={animationOnviewHook}
        className={
          location.pathname === "/" && activeSection === "section-3"
            ? "font-white-h2"
            : "font-black-h2"
        }
      >
        {subTitleHeadlineCompProp}
      </motion.h2>

      <motion.h3
        animate={animationOnviewHookH3}
        className={
          location.pathname === "/" && activeSection === "section-3"
            ? "font-white-h3"
            : "font-black-h3"
        }
      >
        {titleHeadlineCompPropDate}
        <span> {spanHeadlineCompPropDate}</span>
      </motion.h3>
    </motion.div>
  );
};

export default Index;
