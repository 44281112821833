import React from "react";
import { motion, useMotionValue, useTransform } from "framer-motion";

const GrabAndDrag3DImg = ({ children }) => {
  //
  // -------- 3d effect card using framerMotion ------
  const x = useMotionValue(0);
  const y = useMotionValue(0);
  //
  const rotateX = useTransform(y, [-100, 100], [30, -30]);
  const rotateY = useTransform(x, [-100, 100], [-30, 30]);
  // -------- 3d effect card using framerMotion ------
  //
  //

  return (
    <motion.div
      style={{ x, y, rotateX, rotateY, z: 100 }}
      drag
      dragElastic={0.16}
      dragConstraints={{ top: 0, left: 0, right: 0, bottom: 0 }}
      whileTap={{
        cursor: "grabbing",
      }}
      whileHover={{ cursor: "grabbing" }}
    >
      {children}
    </motion.div>
  );
};

export default GrabAndDrag3DImg;
