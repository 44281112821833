import { useEffect } from "react";

import "./styles-largeIMgComp.scss";

import { motion, useAnimation } from "framer-motion";

import { useInView } from "react-intersection-observer";

const Index = ({ img }) => {
  const { ref, inView } = useInView();

  const animationOnviewHookH2 = useAnimation();

  useEffect(() => {
    if (inView) {
      animationOnviewHookH2.start({
        scale: 1,

        transition: {
          ease: [0.6, 0.01, -0.05, 0.95],
          duration: 1.8,

          delay: 0.9,
        },
      });
    }

    if (!inView) {
      animationOnviewHookH2.start({
        scale: 1.5,
      });
    }
  }, [inView, animationOnviewHookH2]);

  return (
    <motion.div className="wrapperImgLargeComp">
      <motion.div ref={ref} className="containerImgLargeComp">
        <motion.div
          className="img-box-largeComp"
          initial={{
            transition: { duration: 0.6 },
          }}
          animate={{
            transition: { duration: 1.8, staggerChildren: 0.1 },
          }}
          exit={{
            borderTopLeftRadius: "0px",
            borderTopRightRadius: "0px",
            borderBottomLeftRadius: "0px",
            borderBottomRightRadius: "0px",

            transition: { duration: 0.6 },
          }}
          //
          //--------
          //
          style={{ position: "relative" }}
        >
          <motion.img
            src={img}
            alt="img drawing"
            animate={animationOnviewHookH2}
          />
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default Index;
