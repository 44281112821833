import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./style-press.scss";
import allPressData from "../../data_press";
import { motion } from "framer-motion";
import Aos from "aos";
import "aos/dist/aos.css";
import { FadeBlurScaleOnEnter, GrabAndDrag3DImg } from "../../components";

const Index = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <section className="wrapper-press">
      <div className="container-press">
        <div className="headline-press">
          <div className="headline-container-press">
            <GrabAndDrag3DImg>
              <motion.h1
                data-aos="fade-down"
                data-aos-easing="linear"
                data-aos-duration="350"
              >
                Press
              </motion.h1>
            </GrabAndDrag3DImg>
          </div>
        </div>

        <div className="container-grid-press">
          <ul className="press-grid">
            {allPressData.map((itemPress) => (
              <li key={`${itemPress.__id}`} className="container-imgPress">
                <NavLink
                  to={`/press-screen/${itemPress.__id}/${itemPress.extraUrlName}`}
                >
                  <FadeBlurScaleOnEnter>
                    <motion.img
                      whileHover={{ scale: 1.1, color: "#3a3a3a" }}
                      className="imgPress-box"
                      src={itemPress.coverImg}
                      alt="image press"
                    />
                  </FadeBlurScaleOnEnter>
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Index;
