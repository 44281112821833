//SCALE on VIEW:  user will see the scaling from 2 to 1 when on view, each time they enter or scroll to the section.

//
//
import { useEffect } from "react";
// ------ FRAMER
// ------------
import { motion, useAnimation } from "framer-motion";
// to show some animation ONLY if its in the VIEWPORT
import { useInView } from "react-intersection-observer";
// -------------
//
// ------ AOS
import Aos from "aos";
import "aos/dist/aos.css";
//
//
//
const BounceXBtn = ({ children }) => {
  //

  // ----- framer-motion -------
  const { ref, inView } = useInView();
  const animationOnviewBounceBtn = useAnimation();

  useEffect(() => {
    if (inView) {
      animationOnviewBounceBtn.start({
        x: 0,
        delayChildren: 0.3,
        staggerChildren: 0.2,
        opacity: 1,
        scale: 1,
        transition: {
          x: {
            // stiffness: 4000,
            // velocity: -100,
            // // delay: 0.7
            type: "spring",
            damping: 9,
            // duration: 1,
            // delay: 0.2,
          },
        },
      });
    }

    if (!inView) {
      animationOnviewBounceBtn.start({
        x: 300,
        opacity: 0,
        transition: {
          x: {
            // stiffness: 4000
            // damping: 18,
          },
        },
      });
    }
    //
  }, [inView, animationOnviewBounceBtn]);

  //
  //
  // ------ AOS -----
  useEffect(() => {
    Aos.init({ duration: 800 });
  }, []);
  //
  //

  return (
    <>
      <motion.div
        // ---- framer
        ref={ref}
        // ---- framer
      >
        <motion.div animate={animationOnviewBounceBtn}> {children}</motion.div>
      </motion.div>
    </>
  );
};

export default BounceXBtn;
